/**
 * @file
 * Contains Public route component.
 *
 *  * Public route contains next attributes:
 *  # exact {boolean} - path matches exactly
 *  # path {string} - path
 *  # component {any} - component
 */

import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { Redirect } from "react-router";
import links from "../routes/urls";
import Bridge from "../bridge";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { profileStateData, updateProfile } from "../slices/profile-slice";
import ProfileService from "../services/profile.service";
import BackdropElement from '../components/BackdropElement/BackdropElement';
import { redirectState } from '../slices/app-slice';

function PublicRoute(props) {
  const {
    component: Component,
    location: { pathname },
    ...rest
  } = props;

  const dispatch = useDispatch();

  const profileData = useSelector(profileStateData);
  const isRedirecting = useSelector(redirectState);

  /**
   * Auth application.
   */
  useEffect(() => {
    const fetchData = async () => {
      if (Object.keys(profileData).length <= 0 && Bridge.getJwtToken()) {
        try {
          const profileResponse = await ProfileService.getProfile();
          dispatch(updateProfile(profileResponse));
        } catch (e) {
          console.error(e);
        }
      }
    };
    fetchData();
  }, [profileData, dispatch]);

  /**
   * Render component.
   * @param {object} componentProps.
   * Component props.
   */
  const renderComponent = (componentProps) => {
    if (
      Object.keys(profileData).length &&
      !profileData?.redirect &&
      Object.values(links.public).includes(pathname)
    ) {
      return <Redirect to={links.dashboard} />;
    }

    return <Component {...componentProps} />;
  };

  return (
    <React.Fragment>
      {isRedirecting ? (
        <BackdropElement />
      ) : (
        <Route exact {...rest} render={renderComponent} />
      )}
    </React.Fragment>
  );
}

PublicRoute.propTypes = {
  exact: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
  component: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
    PropTypes.func,
    PropTypes.node,
  ]).isRequired,
};

export default PublicRoute;
