import React, {useEffect, useState} from "react";
import './CampaignAddNewView.scss';
import { TextField, Button, MenuItem } from "@material-ui/core";
import BackdropElement from "../BackdropElement/BackdropElement";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import FeUtils from "@fe-sales/common-utils/fe-utils";
import { useDispatch, useSelector } from 'react-redux';
import {
  addNewCampaignWindow,
  newCampaignTypes,
  newCampaignStatuses,
  addNewCampaignStatuses,
  addNewCampaignTypes,
} from '../../pages/campaign/campaign-slice';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import campaignService from '../../services/campaign.service';

const CampaignAddNewView = () => {
  const dispatch = useDispatch();

  const statusDropDown = useSelector(newCampaignStatuses);
  const typeDropDown = useSelector(newCampaignTypes);

  const [values, setValues] = useState({});
  const [campaignURL, setCampaignURL] = useState('');
  const [error, setErrorState] = useState({});
  const [copyIcon, setCopyIcon] = useState(false);
  const [dropdownsLoaded, setDropdownsLoaded] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const requiredFields = [
    'name',
    'type',
    'startDate',
    'endDate',
    'status',
    'description',
    'websiteURL',
    'source',
    'medium',
    'term',
    'content'
  ];

  useEffect(() => {
    const fetchData = async () => {
      if (!(statusDropDown?.length || typeDropDown?.length)) {
        try {
          const statusResponse = await campaignService.getCampaignItemStatus();
          const typesResponse = await campaignService.getCampaignItemTypes();
          dispatch(addNewCampaignStatuses(statusResponse))
          dispatch(addNewCampaignTypes(typesResponse))
        } catch (e) {
          console.error(e);
        } finally {
          setDropdownsLoaded(true);
        }
      } else {
        setDropdownsLoaded(true);
      }
    }
    fetchData();
  }, [])

  useEffect(() => {
    if (values?.websiteURL) {
      const includedFields = ['source', 'medium', 'name', 'term', 'content'];
      if (FeUtils.isValidUrl(values?.websiteURL)) {
        const valuesObject = {...values};
        Object.entries(valuesObject).forEach(([key, value]) => {
          if(!includedFields.includes(key) || !value) {
            delete valuesObject[key];
          }
        })
        setCampaignURL(`${values?.websiteURL}?${GFG_Fun(valuesObject)}`)
      } else {
        setCampaignURL('');
      }
    }

    setSubmitDisabled(requiredFields.some(item => !(item in values)))
  }, [values])

  const copyHandler = () => {
    setCopyIcon(true);
    setTimeout(() => setCopyIcon(false), 300);
  }

  const GFG_Fun = (obj) => {
    const params = Object.keys(obj).map((key) => {
      return `utm_${key}=${obj[key].replaceAll(/\s+/g, '%20')}`;
    }).join('&');
    return params;
  }

  const handleChange = (event) => {
    setErrorState({});
    const { id, value, name } = event.target;

    if(id === 'websiteURL' && !FeUtils.isValidUrl(value)) {
      setErrorState({...error, [id]:'Enter valid URL'})
    }
    if(!value) {
      setErrorState({...error, [id]:'Field Required'})
    }
    setValues({ ...values, [id || name]: value });
  };

  const renderDropDown = (data = []) => {
    if (data.length) {
      return data.map((item, index) => (
        <MenuItem value={item} key={index}>{item}</MenuItem>
      ))
    }
  };

  const saveHandler = async (e) => {
    e.preventDefault();
    const data = {...values, ...{campaignURL}}
    try {
      await campaignService.createCampaignItem(data);
      setValues({});
      setCampaignURL('');
      setDropdownsLoaded(false);
      dispatch(addNewCampaignWindow(false));
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="add-new-campaign">
      {dropdownsLoaded ? (
        <React.Fragment>
          <header className="add-new-campaign__header">
            <div className="add-new-campaign__title">
              <h4>New Campaign</h4>
            </div>
            <div>
              <IconButton
                aria-label="close dialog"
                className="add-new-campaign__close-button"
                onClick={() => dispatch(addNewCampaignWindow(false))}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </header>

          <div className="add-new-campaign__container">
            <form className="add-new-campaign__form" onSubmit={saveHandler}>
              <TextField
                required
                id="name"
                label={"Campaign Name"}
                value={values?.name || ''}
                onChange={handleChange}
                error={"name" in error}
                // helperText={error?.key === "mobile" ? error?.msg : ""}
              />
              <TextField
                required
                label="Campaign Type"
                id="type"
                name="type"
                select
                value={values?.type || ''}
                onChange={handleChange}
                error={"type" in error}
              >
                {renderDropDown(typeDropDown)}
              </TextField>
              <TextField
                required
                id="startDate"
                label={"Start Date"}
                type="date"
                value={
                  values?.startDate
                    ? FeUtils.formatDateTime(values.startDate, "yyyy-MM-DD")
                    : ""
                }
                onChange={handleChange}
                className="add-new-campaign__date-input"
                inputProps={
                  values?.endDate
                    ? {
                      max: `${FeUtils.formatDateTime(
                        values.endDate,
                        "yyyy-MM-DD"
                      )}`,
                    }
                    : {}
                }
                InputLabelProps={{
                  shrink: true,
                }}
                error={"startDate" in error}
              />
              <TextField
                required
                id="endDate"
                type="date"
                label={"End Date"}
                value={
                  values?.endDate
                    ? FeUtils.formatDateTime(values.endDate, "yyyy-MM-DD")
                    : ""
                }
                onChange={handleChange}
                className="add-new-campaign__date-input"
                inputProps={
                  values?.startDate
                    ? {
                      min: `${FeUtils.formatDateTime(
                        values.startDate,
                        "yyyy-MM-DD"
                      )}`,
                    }
                    : {}
                }
                InputLabelProps={{
                  shrink: true,
                }}
                error={"endDate" in error}
              />
              <TextField
                required
                label="Campaign Status"
                id="status"
                name="status"
                select
                value={values?.status || ''}
                onChange={handleChange}
                error={"status" in error}
              >
                {renderDropDown(statusDropDown)}
              </TextField>
              <TextField
                required
                id="description"
                label={"Description"}
                value={values?.description || ''}
                onChange={handleChange}
                multiline
                error={"description" in error}
              />
              <TextField
                required
                id="websiteURL"
                label={"Website URL"}
                value={values?.websiteURL || ''}
                onChange={handleChange}
                error={"websiteURL" in error}
              />
              <TextField
                required
                id="source"
                label={"Campaign Source"}
                value={values?.source || ''}
                onChange={handleChange}
                error={"source" in error}
              />
              <TextField
                required
                id="medium"
                label={"Campaign Medium"}
                value={values?.medium || ''}
                onChange={handleChange}
                error={"medium" in error}
              />
              <TextField
                required
                id="term"
                label={"Campaign Term"}
                value={values?.term || ''}
                onChange={handleChange}
                error={"term" in error}
              />
              <TextField
                required
                id="content"
                label={"Campaign Content"}
                value={values?.content || ''}
                onChange={handleChange}
                error={"content" in error}
              />
              <div className="add-new-campaign__copy_container">
                {!!campaignURL && (
                  <CopyToClipboard
                    text={campaignURL}
                    onCopy={copyHandler}
                  >
                    <span>{copyIcon && (<i className="icons8-checked"/>)}Copy URL</span>
                  </CopyToClipboard>
                )}
                <TextField
                  id="link"
                  label={"Generated Campaign URL"}
                  value={campaignURL}
                  multiline
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
              <div className="add-new-campaign__btn_container">
                <Button
                  disabled={submitDisabled || !!Object.keys(error).length}
                  className="add-new-campaign__btn"
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Create Campaign
                </Button>
              </div>
            </form>
          </div>
        </React.Fragment>
      ) : (
        <BackdropElement />
      )}
    </div>
  );
};

export default CampaignAddNewView;

