import React from "react";
import "./page-layout.scss";
import Header from "../header/header";
import LeftNavigation from "../left-navigation/left-navigation";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/src/styles.scss'
import {useDispatch, useSelector} from 'react-redux';
import { updateUrl, audioPlayerSliceUrl } from '../../slices/app-slice';

const PageLayout = ({ children }) => {
    const dispatch = useDispatch();

    const playerUrl = useSelector(audioPlayerSliceUrl);

    const closePlayerHandler = () => {
        dispatch(updateUrl(''));
    };

    return (
        <>
            { !!playerUrl && (
                <div
                    className='audio-player-container'
                    onClick={closePlayerHandler}
                >
                    <AudioPlayer
                        autoPlay
                        src={playerUrl}
                        // onEnded={closePlayerHandler}
                        onError={closePlayerHandler}
                        onPlayError={closePlayerHandler}
                    />
                </div>
            )}

            <Header />
            <section className="cont">
              <LeftNavigation />
              <main className="main">{children}</main>
            </section>
        </>
    );
};

export default PageLayout;
