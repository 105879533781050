import { configureStore } from "@reduxjs/toolkit";
import leadsSlice from "../pages/leads/leads-slice";
import leadSingleSlice from "../components/lead-single-view/lead-single-slice";
import profileSlice from "../slices/profile-slice";
import accountsSlice from "../pages/accounts/accounts-slice";
import usersSlice from "../pages/users/users-slice";
import userSingleSlice from "../components/user-single-view/user-single-slice";
import campaignSlice from "../pages/campaign/campaign-slice";
import callLogsSlice from "../pages/callLogs/call-logs-slice";
import appSlice from '../slices/app-slice';
import searchSlice from "../components/search-dialog/search-slice";
import callRecordingsSlice from '../pages/callRecordings/call-recordings-slice';

export default configureStore({
  reducer: {
    leadsSlice,
    leadSingleSlice,
    profileSlice,
    accountsSlice,
    userSingleSlice,
    usersSlice,
    campaignSlice,
    callLogsSlice,
    appSlice,
    searchSlice,
    callRecordingsSlice,
  },
});
