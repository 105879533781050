import { createSlice } from "@reduxjs/toolkit";

export const leadSingleSlice = createSlice({
  name: "leadSingleSlice",
  initialState: {
    lead: {
      owner: {},
    },
    notes: [],
  },
  reducers: {
    addLead: (state, action) => {
      return action.payload;
    },
    updateLead: (state, action) => {
      state.lead[action.payload.key] = action.payload.value;
    },
    addLeadNote: (state, action) => {
      state.notes.push(action.payload);
    },
  },
});

export const leadSingleState = (state) => state.leadSingleSlice;

export const { addLead, updateLead, addLeadNote } = leadSingleSlice.actions;

export default leadSingleSlice.reducer;
