import { createSlice } from "@reduxjs/toolkit";

export const campaignSlice = createSlice({
  name: "campaignSlice",
  initialState: {
    campaign: [],
    tracks: [],
    addNewCampaignWindow: false,
    status: [],
    type: [],
  },
  reducers: {
    addCampaign: (state, data) => {
      state.campaign = data.payload;
    },
    addCampaignTracks: (state, data) => {
      state.tracks = data.payload;
    },
    addNewCampaignWindow: (state, data) => {
      state.addNewCampaignWindow = data.payload;
    },
    addNewCampaignStatuses: (state, data) => {
      state.status = data.payload;
    },
    addNewCampaignTypes: (state, data) => {
      state.type = data.payload;
    },
  },
});

export const campaignState = (state) => state.campaignSlice.campaign;
export const campaignTracksState = (state) => state.campaignSlice.tracks;
export const addNewCampaignWindowState = (state) => state.campaignSlice.addNewCampaignWindow;
export const newCampaignStatuses = (state) => state.campaignSlice.status;
export const newCampaignTypes = (state) => state.campaignSlice.type;

export const {
  addCampaign,
  addCampaignTracks,
  addNewCampaignWindow,
  addNewCampaignStatuses,
  addNewCampaignTypes,
} = campaignSlice.actions;

export default campaignSlice.reducer;
