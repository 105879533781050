/**
 * @file
 * Contains Sip service.
 */

import { apiClient } from "./apiClients.service";
import {
  USERS_SIP,
  EMAIL_SIP,
  PASSWORD_SIP,
  AVAILABLE_PHONE,
  SIP_DOMAINS,
  RELEASE_PHONE,
  ASSIGN_PHONE,
} from "../constants/apiEnpoints.constant";

/**
 * Get profile sip data.
 * @param {string} id.
 * User ID.
 */
const getSipDataForProfile = async (id) => {
  const response = await apiClient.get(`${USERS_SIP}/${id}`);
  return response.data;
};

/**
 * Update Sip Email.
 * @param {object} data.
 */
const updateSipEmail = async (data) => {
  const response = await apiClient.put(EMAIL_SIP, data);
  return response.data;
};

/**
 * Update Sip Password.
 * @param {object} data.
 */
const updateSipPassword = async (data) => {
  const response = await apiClient.put(PASSWORD_SIP, data);
  return response.data;
};

/**
 * Get phone assigned to user.
 * @param {string} id.
 */
const getAssignedPhone = async (id) => {
  try {
    const response = await apiClient.get(`${USERS_SIP}/${id}`);
    return response.data;
  } catch (e) {
    console.error(e);
  }
};

/**
 * Get available phone.
 */
const getPhoneList = async () => {
  try {
    const response = await apiClient.get(`${AVAILABLE_PHONE}`);
    return response.data;
  } catch (e) {
    console.error(e);
  }
};

/**
 * Get Sip domains assigned to user.
 */
const getSipDomains = async () => {
  try {
    const response = await apiClient.get(`${SIP_DOMAINS}`);
    return response.data;
  } catch (e) {
    console.error(e);
  }
};

/**
 * Release phone.
 * @param {string} phone.
 */
const releasePhone = async (phone) => {
  try {
    const response = await apiClient.put(`${RELEASE_PHONE}/`, { phone });
    return response.data;
  } catch (e) {
    console.error(e);
  }
};

/**
 * Assign phone.
 * @param {object} data.
 */
const assignPhone = async (data) => {
  try {
    const response = await apiClient.post(`${ASSIGN_PHONE}/`, data);
    return response.data;
  } catch (e) {
    console.error(e);
  }
};

export default {
  getSipDataForProfile,
  updateSipEmail,
  updateSipPassword,
  getAssignedPhone,
  getPhoneList,
  getSipDomains,
  releasePhone,
  assignPhone,
};
