import React from "react";
import { Grid } from "@material-ui/core";
import "./forgotPassword.scss";
import { ForgotPasswordPage } from "@fe-sales/common-ui";
import logo from "./../../assets/img/logo.svg";
import urls from "../../routes/urls";
import AuthService from "../../services/auth.service";

const ForgotPasswordComponent = (props) => {
  const { history } = props;

  const sendCodeHandler = async (userCredentials) => {
    const { user } = userCredentials;

    try {
      await AuthService.sendRestoreCodeApi(user);
    } catch (e) {
      console.error(e);
    }
  };

  const updatePasswordHandler = async (userCredentials) => {
    const { code, password } = userCredentials;

    try {
      await AuthService.restorePasswordApi({ code, password });
      history.push({
        pathname: urls.dashboard,
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="containerAuth">
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid
          item
          xs={10}
          sm={10}
          md={6}
          lg={4}
          className="forgot-password-container"
        >
          <ForgotPasswordPage
            forgotPasswordHandler={() => console.log(111)}
            title={"Sales & Marketing"}
            companyLogo={logo}
            styles={{
              logoStyle: { width: "100px", height: "100px" },
              titleStyle: {
                color: "#FFF",
                fontSize: "1.6rem",
                padding: "10px",
              },
              dialogStyle: { backgroundColor: "rgba(255, 255, 255, 0.2)" },
            }}
            onSendCode={sendCodeHandler}
            onUpdatePassword={updatePasswordHandler}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ForgotPasswordComponent;
