import React, { useEffect, useState } from "react";
import "./header.scss";
import { Avatar, Modal } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import links from "../../constants/linkObject";
import ProfileDialog from "../profile-dialog/profile-dialog";
import SearchDialog from "../search-dialog/search-dialog";
import { useDispatch, useSelector } from "react-redux";
import {
  profileStateData,
  profileStateActive,
  toggleProfile,
  profileAvatarState,
} from "../../slices/profile-slice";
import logo from "./../../assets/img/logo_dark.svg";

const Header = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const profileDataActive = useSelector(profileStateActive);
  const profileDataState = useSelector(profileStateData);
  const avatarData = useSelector(profileAvatarState);

  const [profileData, setProfileData] = useState(false);
  const [showSearchDialog, setShowSearchDialog] = useState(false);

  useEffect(() => {
    setProfileData(profileDataState);
  }, [profileDataState]);

  const toggleProfileWindowHandler = () => {
    dispatch(toggleProfile(!profileDataActive));
  };

  const closeSearchDialog = () => {
    setShowSearchDialog(false);
  };

  return (
    <>
      <header className="header">
        <div className="header__logo">
          <img src={logo} alt="FE CRM" />
          <h2>Sales & Marketing</h2>
        </div>
        <h3 className="header__title">
          {
            links[
              Object.keys(links).filter(
                (key) => links[key].url === history.location.pathname
              )
              ].label
          }
        </h3>

        <div className="header__right">
          <div className="header__search" onClick={() => setShowSearchDialog(true)}>
            <i className="icons8-search" />
          </div>
          <div className="header__avatar" onClick={toggleProfileWindowHandler}>
            <div className="header__avatar__name-wrap">
              <div className="header__avatar__name">{profileData?.firstName}</div>
              <div className="header__avatar__last">{profileData?.lastName}</div>
            </div>
            <Avatar
              src={
                avatarData ? avatarData : "assets/img/profile-default.png"
              }
            />
          </div>
        </div>
      </header>
      <Modal
        open={profileDataActive}
        onClose={toggleProfileWindowHandler}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div>
          <ProfileDialog closeProfile={toggleProfileWindowHandler} />
        </div>
      </Modal>

      <Modal
        open={showSearchDialog}
        onClose={closeSearchDialog}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div>
          <SearchDialog  closeSearch={closeSearchDialog}/>
        </div>
      </Modal>
    </>
  );
};

export default Header;
