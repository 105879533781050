import React, {useState, useEffect} from 'react';
import './search-dialog.scss';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import LeadsService from "../../services/leads.service";
import {searchLeads, searchState } from "./search-slice";
import {useDispatch, useSelector} from "react-redux";
import feUtils from "@fe-sales/common-utils";

const SearchDialog = ({closeSearch}) => {

  const dispatch = useDispatch();

  const searchStateData = useSelector(searchState);

  let { leads } = searchStateData;

  const [search, setSearch] = useState('');

  const handleChange = (text) => {
    setSearch(text);
  };

  const handleClickClose = () => {
    closeSearch();
    console.log("close pressed");
  };

  const searchlHandler = (e) => {
    e.preventDefault();
    console.log("Search btn pressed");
  }

  const fetchData = async () => {
    try {

      if (!search || search.length < 2) {
        return false;
      }

      const response = await LeadsService.searchLeads({
        search,
        // filter: {
        //   limit: 10,
        //   skip: 0
        // }
      });
      dispatch(searchLeads(response));
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, [dispatch, search]);

  const renderLeads = () => leads.map((item, i) => {
    const {name, type, created, email, companyName, source, status, description} = item;
    return (
      <li key={i}>
        <div className="search__list-title">
          <h5>{name}</h5>
          <span>{type}</span>
        </div>

        <div className="search__list-info">
          <span>{companyName}</span>
          <span>Created: {feUtils.formatDateTime(created)}</span>
        </div>

        <div className="search__list-content">
          <span>{email} | {source} | {status}</span>
        </div>
        <footer>
          <span>{description}</span>
        </footer>
      </li>
    )
  });

  return (
    <div className="searchDialog">
      <header className="searchDialog__header">
        <div className="searchDialog__form">
          <form className="searchDialog__form" onSubmit={searchlHandler}>
            {/*<InputLabel htmlFor="input-with-icon-adornment">Search</InputLabel>*/}
            <Input
              id="input-with-icon-adornment"
              label={'Search'}
              value={search}
              onChange={(event) => handleChange(event.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <i className="icons8-search"></i>
                </InputAdornment>
              }
            />
          </form>
        </div>
        <div>
          <IconButton aria-label="close dialog" className="searchDialog__close" onClick={handleClickClose}>
            <CloseIcon/>
          </IconButton>
        </div>
      </header>

      <div>
        {!!leads.length && (
          <div className="search__result">
            <span><b>{leads.length}</b> Results Found</span>
          </div>
        )}
        <div className="searchDialog__container">
          {!!leads.length && (
            <ul className="search__list">
              {renderLeads()}
            </ul>
          )}
        </div>
      </div>
    </div>
  );

}

export default SearchDialog;
