/**
 * @file
 * Contains Login component.
 *
 * Login contains next attributes:
 *  # title {string} - Rewrite default title.
 *  # companyLogo {node} - Set up custom logo.
 *  # onSignUpUser {function} - handle sign up user form submit.
 *  # onLoginHandler {function} - handle login button click.
 *  # styles {object} - Rewrite default styles. Contain of three keys - logoStyle, titleStyle, dialogStyle.
 *  # error {object} - Error handling. Contain of two keys - {user, password}.
 */

import React, { useState } from "react";
import { TextField, Button } from "@material-ui/core";
import "./signUpUser.css";
import FeUtils from "@fe-sales/common-utils";
import DefaultLogo from "./../assets/img/logo512.png";
import PropTypes from "prop-types";

const SignUpUserComponent = (props) => {
  const {
    title,
    companyLogo,
    styles,
    error,
    onSignUpUser,
    onLoginHandler,
  } = props;

  /**
   * User state.
   */
  const [user, setUser] = useState("");

  /**
   * Password state.
   */
  const [password, setPassword] = useState("");

  /**
   * RePassword state.
   */
  const [repassword, setRePassword] = useState("");

  /**
   * User error state.
   */
  const [userError, setUserError] = useState(true);

  /**
   * Password error state.
   */
  const [passwordError, setPasswordError] = useState(true);

  /**
   * RePassword error state.
   */
  const [rePasswordError, setRePasswordError] = useState(true);

  const updateEmailHandler = (e) => {
    if (FeUtils.validateEmail(e.target.value)) {
      setUserError(false);
      setUser(e.target.value);
    } else {
      setUserError(true);
    }
  };

  const updatePassword = (e) => {
    if (e?.target?.value?.length) {
      setPassword(e.target.value);
      setPasswordError(false);
    } else {
      setPasswordError(true);
    }
  };

  const updateRePassword = (e) => {
    if (e?.target?.value?.length) {
      setRePassword(e.target.value);
      setRePasswordError(false);
    } else {
      setRePasswordError(true);
    }
  };

  /*------- Seconf Form -----*/

  /**
   * Code state.
   */
  const [code, setCode] = useState("");

  const updateCode = (e) => {
    if (e?.target?.value?.length) {
      setCode(e.target.value);
    }
  };

  const signUpUserHandler = (e) => {
    e.preventDefault();
    if (!userError || !passwordError) {
      onSignUpUser({
        user,
        code,
        password,
        repassword,
      });
    }
  };

  return (
    <div>
      <img
        src={companyLogo ? companyLogo : DefaultLogo}
        alt=""
        className="cui_signup_user_logo"
        style={styles?.logoStyle}
      />
      <div className="cui_signup_user_title" style={styles?.titleStyle}>
        {title ? title : "Welcome"}
      </div>
      <div className="cui_signup_user_dialog" style={styles?.dialogStyle}>
        <form className="cui_signup_user_form" onSubmit={signUpUserHandler}>
          <TextField
            required
            id="user"
            label="Email"
            value={user}
            onChange={updateEmailHandler}
            error={!!error?.user}
            helperText={!!error?.user ? error?.user : ""}
          />
          <TextField
            required
            id="code"
            label="Code"
            // value={email}
            onChange={updateCode}
            error={!!error?.user}
            helperText={!!error?.code ? error?.code : ""}
          />
          <TextField
            required
            id="password"
            label="Password"
            type="password"
            // value={password}
            onChange={updatePassword}
            error={!!error?.password}
            helperText={!!error?.password ? error?.password : ""}
          />
          <TextField
            required
            id="repassword"
            label="Repeat Password"
            type="password"
            // value={password}
            onChange={updateRePassword}
            error={!!error?.rePassword}
            helperText={!!error?.rePassword ? error?.rePassword : ""}
          />
          <Button
            variant="contained"
            type="submit"
            style={styles?.buttonStyle}
            disabled={userError || passwordError}
          >
            Sign Up
          </Button>

          <Button
            className="cui_signup_user_login"
            disableElevation
            onClick={onLoginHandler}
          >
            Login
          </Button>
        </form>
      </div>
    </div>
  );
};
SignUpUserComponent.propTypes = {
  title: PropTypes.string,
  companyLogo: PropTypes.node,
  styles: PropTypes.object,
  error: PropTypes.object,
  onSignUpUser: PropTypes.func,
  onLoginHandler: PropTypes.func,
};

export default SignUpUserComponent;
