import React from "react";
import "./Pagination.scss";

const Pagination = (props) => {
  const {
    tableData,
    paginationState,
    paginationClickHandler,
  } = props;

  if (paginationState == 0 && tableData.length == 0) {
    return (
      <></>
    );
  }

  return (
    <div className={`pagination ${tableData?.length < 10 && 'empty-table'}`}>
      <i
        className={`icons8-chevron-left ${paginationState === 0 && 'disabled'}`}
        onClick={() => paginationClickHandler('prev')}
      />
      <i
        className={`icons8-chevron-right ${tableData?.length < 10 && 'disabled'}`}
        onClick={() => paginationClickHandler('next')}
      />
    </div>
  )
}

export default Pagination;

