import { createSlice } from "@reduxjs/toolkit";

export const profileSlice = createSlice({
  name: "profileSlice",
  initialState: {
    data: {},
    active: false,
    avatar: "",
  },
  reducers: {
    updateProfile: (state, data) => {
      state.data = data.payload;
    },
    updateAvatar: (state, data) => {
      state.avatar = data.payload;
    },
    toggleProfile: (state, action) => {
      state.active = action.payload;
    },
  },
});

export const profileStateData = (state) => state.profileSlice.data;
export const profileStateActive = (state) => state.profileSlice.active;
export const profileAvatarState = (state) => state.profileSlice.avatar;

export const {
  updateProfile,
  toggleProfile,
  updateAvatar,
} = profileSlice.actions;

export default profileSlice.reducer;
