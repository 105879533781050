/**
 * @file
 * Contains User service.
 */

import { apiClient } from "./apiClients.service";
import {
  STATUS_USERS_ENDPOINT,
  SALES_USERS_ENDPOINT,
  INVITE_USER,
  SALES_USERS_ROLES_ENDPOINT,
  USERS_BY_ROLES_ENDPOINT,
} from "../constants/apiEnpoints.constant";

/**
 * Get Users List.
 */
const getUsersList = async (params = {}) => {
  try {
    const response = await apiClient.get(SALES_USERS_ENDPOINT, { params });
    return response.data;
  } catch (e) {
    console.error(e);
  }
};

/**
 * Get Users details.
 * @param {string} id.
 * Lead ID
 */
const getUser = async (id) => {
  try {
    const response = await apiClient.get(`${SALES_USERS_ENDPOINT}/${id}`);
    return response.data;
  } catch (e) {
    console.error(e);
  }
};

const getUserRoles = async (id) => {
  try {
    const response = await apiClient.get(`${SALES_USERS_ROLES_ENDPOINT}/${id}`);
    return response.data;
  } catch (e) {
    console.error(e);
  }
};

const getUserListByRoles = async () => {
  try {
    const response = await apiClient.get(`${USERS_BY_ROLES_ENDPOINT}?type=lead_owners`);
    return response.data;
  } catch (e) {
    console.error(e);
  }
};

/**
 * Update User.
 * @param {object} field.
 * Updated field.
 */
const updateUser = async (field) => {
  try {
    await apiClient.put(`${SALES_USERS_ENDPOINT}`, field);
  } catch (e) {
    console.error(e);
  }
};

/**
 * Disable User.
 * @param {object} field.
 */
const disableUser = async (field) => {
  try {
    await apiClient.put(`${STATUS_USERS_ENDPOINT}`, field);
  } catch (e) {
    console.error(e);
  }
};

/**
 * Add New Usre.
 * @param {object} field.
 * Updated field.
 */
const addUser = async (field) => {
  try {
    await apiClient.post(`${INVITE_USER}`, field);
  } catch (e) {
    console.error(e);
  }
};

export default {
  getUsersList,
  getUser,
  updateUser,
  disableUser,
  addUser,
  getUserRoles,
  getUserListByRoles,
};
