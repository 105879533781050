/**
 * @file
 * Call Logs Filter component.
 */

import React, { useEffect, useState } from "react";
import "./CallLogsFilter.scss";
import CloseIcon from "@material-ui/icons/Close";
import { TextField, IconButton, Button, Radio } from "@material-ui/core";
import FeUtils from "@fe-sales/common-utils";
import moment from "moment";

const CallLogsFilter = (props) => {
  const {
    closeFilterHandler,
    filterData,
    returnFilterData = {},
    resetFilters,
  } = props;

  let initialState = {
    startTime: "",
    endTime: "",
    from: "",
    to: "",
    status: "",
  };

  useEffect(() => {
    Object.keys(returnFilterData).length && setFilterState(returnFilterData);
  }, [returnFilterData]);

  const [filterState, setFilterState] = useState(initialState);

  const inputEditingStatusHandler = (e) => {
    let value = e.target.value;
    if (e.target.id === "endTime" || e.target.id === "startTime") {
      // value = (new Date(e.target.value)).toISOString();
      value = moment(e.target.value).toISOString();
    }
    setFilterState({ ...filterState, [e.target.id]: value });
  };

  const checkboxChangeHandler = (e) => {
    setFilterState({ ...filterState, status: e.target.id });
  };

  const clearFilterHandler = () => {
    setFilterState(initialState);
    resetFilters();
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const obj = { ...filterState };
    Object.keys(obj).forEach((k) => {
      (obj[k] === null || !obj[k].length) && delete obj[k];
    });
    if (Object.keys(obj).length) {
      filterData(obj);
    }
    closeFilterHandler();
  };

  return (
    <div className="filterDialog">
      <header className="filterDialog__header">
        <div className="filterDialog__title">Filter Call Log</div>
        <IconButton
          aria-label="close dialog"
          className="filterDialog__close"
          onClick={() => closeFilterHandler()}
        >
          <CloseIcon />
        </IconButton>
      </header>

      <div className="filterDialog__container">
        <form
          className="filterDialog__container-form"
          noValidate
          onSubmit={submitHandler}
        >
          <div>
            <div className="filterDialog__input-title">Date From</div>
            <TextField
              id="startTime"
              type="date"
              value={
                filterState?.startTime
                  ? FeUtils.formatDateTime(filterState.startTime, "yyyy-MM-DD")
                  : ""
              }
              onChange={inputEditingStatusHandler}
              className="filterDialog__date-input"
              inputProps={
                filterState.endTime
                  ? {
                      max: `${FeUtils.formatDateTime(
                        filterState.endTime,
                        "yyyy-MM-DD"
                      )}`,
                    }
                  : {}
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div>
            <div className="filterDialog__input-title">Date To</div>
            <TextField
              id="endTime"
              type="date"
              value={
                filterState.endTime
                  ? FeUtils.formatDateTime(filterState.endTime, "yyyy-MM-DD")
                  : ""
              }
              onChange={inputEditingStatusHandler}
              className="filterDialog__date-input"
              inputProps={
                filterState.startTime
                  ? {
                      min: `${FeUtils.formatDateTime(
                        filterState.startTime,
                        "yyyy-MM-DD"
                      )}`,
                    }
                  : {}
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div>
            <div className="filterDialog__input-title">Call From</div>
            <TextField
              id="from"
              value={filterState?.from ? filterState?.from : ""}
              className="filterDialog__date-input"
              onChange={inputEditingStatusHandler}
            />
          </div>
          <div>
            <div className="filterDialog__input-title">Call To</div>
            <TextField
              id="to"
              value={filterState?.to ? filterState?.to : ""}
              className="filterDialog__date-input"
              onChange={inputEditingStatusHandler}
            />
          </div>
          <div className="filterDialog__checkbox-wrap">
            <div className="filterDialog__input-title checkbox-title">
              Status
            </div>
            <div>
              <Radio
                checked={filterState.status === "completed"}
                onChange={checkboxChangeHandler}
                id="completed"
                color="default"
              />
              <em>Completed</em>
            </div>
            <div>
              <Radio
                checked={filterState.status === "failed"}
                onChange={checkboxChangeHandler}
                id="failed"
                color="default"
              />
              <em>Failed</em>
            </div>
            <div>
              <Radio
                checked={filterState.status === "no-answer"}
                onChange={checkboxChangeHandler}
                id="no-answer"
                color="default"
              />
              <em>No Answer</em>
            </div>
            <div>
              <Radio
                checked={filterState.status === "in-progress"}
                onChange={checkboxChangeHandler}
                id="in-progress"
                color="default"
              />
              <em>In Progress</em>
            </div>
            <div>
              <Radio
                checked={filterState.status === "busy"}
                onChange={checkboxChangeHandler}
                id="busy"
                color="default"
              />
              <em>Busy</em>
            </div>
            <div>
              <Radio
                checked={filterState.status === "queued"}
                onChange={checkboxChangeHandler}
                id="queued"
                color="default"
              />
              <em>Queued</em>
            </div>
          </div>
          <div className="filterDialog__btn-wrap">
            <Button
              className="filterDialog__reset-filter-btn"
              onClick={clearFilterHandler}
              variant="outlined"
            >
              Reset Filters
            </Button>
            <Button
              className="filterDialog__filter-btn"
              variant="contained"
              disableElevation
              type="submit"
              color="primary"
            >
              Filter
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CallLogsFilter;
