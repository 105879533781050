import React, { useEffect, useState } from "react";
import { TextField, Button } from "@material-ui/core";
import UserService from "../../services/users.service";
import BackdropElement from "../BackdropElement/BackdropElement";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FeUtils from "@fe-sales/common-utils/fe-utils";
import AssignPhoneFormComponent from "../AssignPhoneFormComponent/AssignPhoneFormComponent";
import { useSelector } from 'react-redux';
import { rolesState } from '../../pages/users/users-slice';

const UserSingleView = ({ id, closeSingleUserHandler, usersUpdate }) => {
  const [initialValues, setInitialValues] = useState({});
  const [values, setValues] = useState({});

  const rolesList = useSelector(rolesState);

  const [roleValue, setRoleValue] = useState([]);
  const [initialRoleValue, setInitialRoleValue] = useState([]);
  const [disableUser, setDisableUser] = useState(true);
  const [error, setErrorState] = useState({});
  const [disableBtn, setDisableBtn] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (!Object.keys(values).length || values.id !== id) {
        try {
          setValues({});
          setRoleValue([]);

          const response = await UserService.getUser(id);
          setInitialValues(response);
          setValues(response);

          const responseRoles = await UserService.getUserRoles(id);
          setInitialRoleValue(responseRoles || []);
          setRoleValue(responseRoles || []);
        } catch (e) {
          closeSingleUserHandler();
          console.error(e);
        }
      }
    };
    fetchData();
  }, [id, closeSingleUserHandler]);

  const sortedObject = (unordered) => {
    return Object.keys(unordered).sort().reduce(
      (obj, key) => {
        obj[key] = unordered[key];
        return obj;
      }, {});
  }

  useEffect(() => {
    if (Object.keys(error)?.length
      || (JSON.stringify(sortedObject(initialRoleValue)) !== JSON.stringify(sortedObject(roleValue)))
      || (JSON.stringify(values) !== JSON.stringify(initialValues))
    ) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
  }, [error, roleValue, values])

  const handleChange = (event) => {
    const { id, value } = event?.target || {};
    let updatedValue = value;
    if (id === "email") {
      if (!FeUtils.validateEmail(value)) {
        setErrorState({ key: "email", msg: "Enter valid email" });
      } else {
        setErrorState({});
      }
    }

    if (id === "mobile") {
      if (value[0] !== "+") {
        updatedValue = `+${value}`;
      }
      if (!FeUtils.phoneNumberValidation(updatedValue)) {
        setErrorState({ key: "mobile", msg: "Enter valid mobile" });
      } else {
        setErrorState({});
      }
    }
    setValues({ ...values, [id]: updatedValue });
  };

  const handleChangeRoles = (event) => {
    if (event.target.checked) {
      const roleDetails = rolesList?.filter((item) => item?.id === event.target.name)?.[0] || {};
      setRoleValue(oldArray => [...oldArray, roleDetails]);
    } else {
      setRoleValue(roleValue.filter(item => item.id !== event.target.name));
    }
  };

  const saveHandler = async (e) => {
    e.preventDefault();

    const sendValues = { ...values, id, roles: roleValue };
    try {
      await UserService.updateUser(sendValues);
      setDisableUser(true);
      closeSingleUserHandler();
      usersUpdate();
    } catch (e) {
      console.error(e);
    }
  };

  const handleDisable = async (e) => {
    e.preventDefault();

    const sendValues = { userId: id, isEnabled: false };
    try {
      await UserService.disableUser(sendValues);
      closeSingleUserHandler();
      usersUpdate();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="profileDialog">
      {!!Object.keys(values)?.length ? (
        <React.Fragment>
          <header className="profileDialog__header">
            <div className="profileDialog__title">
              <h4>User details</h4>
            </div>
            <div>
              <IconButton
                aria-label="close dialog"
                className="profileDialog__close"
                onClick={() => closeSingleUserHandler()}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </header>

          <div className="profileDialog__container">
            {!disableUser ? (
              <div>
                <p>Are your sure you want disable this user?</p>
                <Button
                  onClick={handleDisable}
                  className="profileDialog__btn"
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Disable
                </Button>
              </div>
            ) : (
              <section>
              <form className="profileDialog__form" onSubmit={saveHandler}>
                <TextField
                  required
                  id="firstName"
                  label={"First Name"}
                  value={values?.firstName}
                  onChange={handleChange}
                />
                <TextField
                  required
                  id="lastName"
                  label={"Last Name"}
                  value={values?.lastName}
                  onChange={handleChange}
                />
                <TextField
                  required
                  id="email"
                  label={"Email"}
                  value={values?.email}
                  onChange={handleChange}
                  error={error?.key === "email"}
                  helperText={error?.key === "email" ? error?.msg : ""}
                />
                <TextField
                  required
                  id="mobile"
                  label={"Mobile"}
                  value={values?.mobile}
                  onChange={handleChange}
                  error={error?.key === "mobile"}
                  helperText={error?.key === "mobile" ? error?.msg : ""}
                  placeholder="+..."
                />
                <FormControl
                  component="fieldset"
                  className="profileDialog__roles"
                >
                  {!!rolesList?.length && (
                    <>
                      <FormLabel component="legend">Roles</FormLabel>
                      <FormGroup aria-label="roles">
                        {rolesList.map((item, index) => {
                          return (
                            <FormControlLabel
                              key={`${item.id}-${index}`}
                              control={
                                <Checkbox
                                  name={item.id}
                                  checked={roleValue.some((i) => i?.id === item?.id)}
                                  onChange={handleChangeRoles}
                                  disabled={!item.enabled}
                                />
                              }
                              label={item.name}
                            />
                          );
                        })}
                      </FormGroup>
                    </>
                  )}
                </FormControl>
                <FormControl
                  component="fieldset"
                  className="profileDialog__roles"
                >
                  <FormLabel component="legend">Status</FormLabel>
                  <div className="profileDialog__status">
                    {values?.status}
                    {values.status !== "Inactive" ? (
                      <button onClick={() => setDisableUser(false)}>
                        Disable User
                      </button>
                    ) : (
                      false
                    )}
                  </div>
                </FormControl>

                <div className="profileDialog__btn_container">
                  <Button
                    disabled={disableBtn}
                    className="profileDialog__btn"
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    Update User
                  </Button>
                </div>
              </form>
              {values?.id &&
                <AssignPhoneFormComponent userId={values?.id} />
              }
            </section>
            )}
          </div>
        </React.Fragment>
      ) : (
        <BackdropElement />
      )}
    </div>
  );
};

export default UserSingleView;
