import React from 'react';
import './MobileTableView.scss';
import FeUtils from '@fe-sales/common-utils/fe-utils';

const MobileTableElementView = (props) => {
  const {tableCells, tableData, tableRowClickHandler, activeRow, playAudioHandler} = props;

  const itemRender = (key, row, index) => {
    if (tableCells[key]?.innerObject && (tableCells[key]?.innerObject in row)) {
      if (tableCells[key].extra === "dates") {
        return (
          <div key={index} className={`mobile-table__item--inner__${key}`}>
            {FeUtils.formatDateTime(row[tableCells[key]?.innerObject][key])}
          </div>
        );
      }

      return <div key={index}>{row[tableCells[key]?.innerObject][key]}</div>;

    } else {
      if (tableCells[key]?.innerObject) {
        return (
          <div key={index} />
        );
      }

      if ((tableCells[key].extra === "dates")) {
        let format = "DD MMM, YYYY";
        if ("format" in tableCells[key]) {
          format = tableCells[key].format;
        }

        return (
          <div key={index}  className={`mobile-table__item--inner__${key}`}>
            {FeUtils.formatDateTime(row[key], format)}
          </div>
        );
      }
      if (key === "owner") {
        return (
          <div key={index}>
            {row[key]?.firstName} {row[key]?.lastName}
          </div>
        );
      }
      if (tableCells[key].extra === "time") {
        return (
          <div key={index} className={`mobile-table__item--inner__record-time`}>
            <div key={index}  className='mobile-table__item--inner__recording'>{
              !!row?.recordings?.length
                && (
                  row?.recordings.map((item, m) => {
                    return (<i className="icons8-circled-play play-icon" key={m} onClick={() => playAudioHandler(item)}/>)
                  })
                )
            }</div>
            {FeUtils.secondsToHms(row[key])}
          </div>
        );
      }
      if (key === "roles") {
        return <div key={index}>{
          row?.roles?.length
            ? (
              row?.roles.map((item, n) => {
                return (<div key={n}>{item.name}</div>)
              })
            )
            : '-'
        }</div>;
      }

      if (key !== "recording") {
        return <div key={index} className={`mobile-table__item--inner__${key}`}>{row[key]}</div>;
      } else {
        return null;
      }
    }
  }

  const renderRow = (data) => {
    if (data.length) {
      const columnItems = Math.ceil(Object.keys(tableCells).length / 2);
      let leftColumnItems = {};
      let rightColumnItems = {};
      let centerColumnItems = {};

      Object.entries(tableCells).forEach(([k, v], i) => {
        if (k === 'from' || k === 'to') {
          centerColumnItems[k] = v;
        } else if ((i + 1) <= columnItems) {
          leftColumnItems[k] = v;
        } else {
          rightColumnItems[k] = v;
        }
      })

      return data.map((row, i) => (
        <div
          key={i}
          onClick={() => !!tableRowClickHandler && tableRowClickHandler(row.id)}
          className={`mobile-table__item ${activeRow && row.id === activeRow ? "active" : ""} ${!tableRowClickHandler ? "no-event" : ""}`}
        >
          {!!Object.keys(leftColumnItems)?.length && (
            <div className='mobile-table__item--left'>
              {Object.keys(leftColumnItems).map((key, index) => itemRender(key, row, index))}
            </div>
          )}
          {!!Object.keys(rightColumnItems)?.length && (
            <div className='mobile-table__item--right'>
              {Object.keys(rightColumnItems).map((key, index) => itemRender(key, row, index))}
            </div>
          )}
          {!!Object.keys(centerColumnItems)?.length && (
            <div className='mobile-table__item--center'>
              {Object.keys(centerColumnItems).map((key, index) => {
                if (index === 0) {
                  return <React.Fragment>{itemRender(key, row, index)} <i className="icons8-more-than"/></React.Fragment>
                } else {
                  return itemRender(key, row, index);
                }
                })
              }
            </div>
          )}
        </div>
      ));
    }
  }

  return (
    <div className='mobile-table'>
      {renderRow(tableData)}
    </div>
  )
};

export default MobileTableElementView;
