import { createSlice, current } from "@reduxjs/toolkit";

export const callLogsSlice = createSlice({
  name: "callLogsSlice",
  initialState: [],
  reducers: {
    addCallLogs: (state, data) => {
      return data.payload;
    },
  },
});

export const callLogsState = (state) => state.callLogsSlice;

export const { addCallLogs } = callLogsSlice.actions;

export default callLogsSlice.reducer;
