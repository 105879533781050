/**
 * @file
 * Contains Call Logs service.
 */
import { apiClient } from "./apiClients.service";
import { CALL_LOGS_ENDPOINT, CALL_RECORDINGS_ENDPOINT } from "../constants/apiEnpoints.constant";

/**
 * Get Leads List.
 */
const getCallLogsList = async (params) => {
  try {
    const response = await apiClient.post(CALL_LOGS_ENDPOINT, params);
    return response.data;
  } catch (e) {
    console.error(e);
  }
};

/**
 * Get Call Recordings.
 */
const getCallRecordings = async (params) => {
  try {
    const response = await apiClient.post(CALL_RECORDINGS_ENDPOINT, params);
    return response.data;
  } catch (e) {
    console.error(e);
  }
};

export default {
  getCallLogsList,
  getCallRecordings,
};
