/**
 * @file
 * Contains Root redirect component.
 */

import React from "react";
import { Redirect } from "react-router";
import links from "../routes/urls";
import Bridge from "../bridge";
import { useSelector } from "react-redux";
import { profileStateData } from "../slices/profile-slice";

function RootRedirect() {
  const profileData = useSelector(profileStateData);

  /**
   * Get link.
   */
  const getLink = () => {
    if (Object.keys(profileData).length <= 0 && !Bridge.getJwtToken()) {
      return links.public.login;
    }
    return links.dashboard;
  };

  return <Redirect to={getLink()} />;
}

export default RootRedirect;
