import { createSlice } from "@reduxjs/toolkit";

export const searchSlice = createSlice({
  name: "searchSlice",
  initialState: {
    leads: [],
  },
  reducers: {
    searchLeads: (state, data) => {
      state.leads = data.payload;
    },
  },
});

export const searchState = (state) => state.searchSlice;

export const { searchLeads } = searchSlice.actions;

export default searchSlice.reducer;
