import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  profileStateData,
  profileAvatarState,
  updateProfile,
  updateAvatar,
} from "../../slices/profile-slice";
import ProfileService from "../../services/profile.service";
import SipService from "../../services/sip.service";
import FeUtils from "@fe-sales/common-utils/fe-utils";
import { CommonProfileDialog } from "@fe-sales/common-ui";

const ProfileDialog = (props) => {
  const { closeProfile } = props;
  const dispatch = useDispatch();
  const profileData = useSelector(profileStateData);
  const avatarData = useSelector(profileAvatarState);

  const [sipData, setSipData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const profileResponse = await ProfileService.getProfile();
        dispatch(updateProfile(profileResponse));

        if (profileResponse?.id) {
          const sipDataResponse = await SipService.getSipDataForProfile(profileResponse?.id)
          setSipData(sipDataResponse);
        }

        const profileResponseAvatar = await ProfileService.getProfileAvatar();
        if (profileResponseAvatar !== "No media file found.") {
          const image = await FeUtils.toBase64(profileResponseAvatar);
          dispatch(updateAvatar(image));
        }
      } catch (e) {
        console.error(e);
      }
    };

    fetchData();
  }, [dispatch]);

  const updateProfileHandler = (data) => {
    dispatch(updateProfile(data));
  }

  const updateAvatarHandler = (img) => {
    dispatch(updateAvatar(img))
  }

  return <CommonProfileDialog
    closeProfile={closeProfile}
    profileData={profileData}
    avatarData={avatarData}
    ProfileService={ProfileService}
    updateProfile={updateProfileHandler}
    commonSipData={sipData}
    updateAvatar={updateAvatarHandler}
  />
};

export default ProfileDialog;
