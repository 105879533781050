import React, { useEffect, useState, useRef } from "react";
import "./TextFieldElement.scss";
import { TextField } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import FeUtils from "@fe-sales/common-utils";

const TextFieldElement = (props) => {
  const { id, state, updateHandler, editing, error, multiline = false, ...rest } = props;

  const [editField, setEditField] = useState(false);

  const [componentError, setComponentError] = useState(false);

  const [editedValue, setEditedValue] = useState("");

  const [editedValueState, setEditedValueState] = useState(false);

  let textInput = useRef(null);

  const validateHandler = (id, value) => {
    if (!value) {
      setComponentError(true);
    } else if ((['phone', 'fax', 'mobile'].includes(id)) && !FeUtils.phoneNumberValidation(value)) {
      setComponentError(true);
    } else if ((id === "email" || id === "secondaryEmail") && !FeUtils.validateEmail(value)) {
      setComponentError(true);
    } else {
      setComponentError(false);
    }
  };

  useEffect(() => {
    if (editField) {
      textInput.current.focus();
      validateHandler(id, state[id]);
    }
  }, [editField]);

  const fieldClickHandler = () => {
    setEditField(true);
    editing(true);
  };

  const inputOnChangeHandler = (e) => {
    setComponentError(false);
    const { id, value } = e.target;
    let updatedValue = value;
    if ((id === "mobile" || id === "fax") && value[0] !== "+") {
      updatedValue = `+${value}`;
    }
    if (id === "noOfEmployees" && value[0] === "-") {
      updatedValue = value.substring(1);
    }
    validateHandler(id, updatedValue);

    setEditedValueState(true);
    setEditedValue(updatedValue);
  };

  const closeHandler = () => {
    textInput.current.value = "";
    setEditedValue("");
    setEditField(false);
    setEditedValueState(false);
    editing(false);
    setComponentError(false);
  };

  const updateDataHandler = () => {
    //validation
    if (!editedValue || editedValue === "+") {
      setComponentError(true);
      return;
    }
    if (id === "phone" && !FeUtils.phoneNumberValidation(editedValue)) {
      setComponentError(true);
      return;
    }
    if (id === "email" && !FeUtils.validateEmail(editedValue)) {
      setComponentError(true);
      return;
    }

    updateHandler({ key: id, value: editedValue });
    closeHandler();
  };

  return (
    <div className="text-field-element">
      <TextField
        id={id}
        value={editedValueState ? editedValue : state?.[id]}
        className={`${editField ? "edit" : ""} ${
          componentError ? "error" : ""
        }`}
        onChange={inputOnChangeHandler}
        InputProps={{
          readOnly: !editField,
          endAdornment: (
            <InputAdornment position="end" onClick={fieldClickHandler}>
              <i className="icons8-edit" />
            </InputAdornment>
          ),
        }}
        error={error?.key === id}
        helperText={error?.key === id ? error?.msg : ""}
        inputProps={rest}
        inputRef={textInput}
        multiline={multiline}
        {...rest}
      />
      {editField && (
        <div className="text-field-element__actions">
          <i
            className={`icons8-checked ${componentError && "validate"}`}
            onClick={updateDataHandler}
          />
          <i className="icons8-cancel" onClick={closeHandler} />
        </div>
      )}
    </div>
  );
};

export default TextFieldElement;
