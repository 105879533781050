import React from "react";
import { Backdrop } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const BackdropElement = (props) => {
  const { loader = true, handleClose } = props;
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={true} onClick={handleClose}>
      {loader && <CircularProgress color="inherit" />}
    </Backdrop>
  );
};

export default BackdropElement;
