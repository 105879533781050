import React, { useEffect, useState } from "react";
import "./SingleView.scss";
import { Avatar, Select, MenuItem } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import { useDispatch, useSelector } from "react-redux";
import TextFieldElement from "../TextFieldElement/TextFieldElement";
import BackdropElement from "../BackdropElement/BackdropElement";
import { leadsState, updateLead } from "../../pages/leads/leads-slice";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import AccountsService from "../../services/accounts.service";
import LeadsService from "../../services/leads.service";
import CampaignService from "../../services/campaign.service";
import { updateAccount } from "../../pages/accounts/accounts-slice";
import { leadSingleState } from '../lead-single-view/lead-single-slice';
import FeUtils from "@fe-sales/common-utils";
import NotesComponent from '../../components/NotesComponent/NotesComponent';
import ContactFormComponent from '../ContactFormComponent/ContactFormComponent';

const AccountSingleView = (props) => {
  const { id, closeSingleViewHandler, type, updateParentComponent } = props;

  const dispatch = useDispatch();

  const { status, users } = useSelector(leadsState);
  const leadState = useSelector(leadSingleState);

  const [mainData, setMainData] = useState({});
  const [notes, setNotes] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [errorState, setErrorState] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        setMainData({});
        setNotes([]);
        if (type === 'lead') {
          const { lead, notes } = await LeadsService.getLead(id);
          setMainData(lead);
          setNotes(notes);
        } else if (type === 'account') {
          const { account, notes } = await AccountsService.getAccount(id);
          setMainData(account);
          setNotes(notes);
        } else if (type === 'campaign') {
          const campaign = await CampaignService.getCampaign(id);
          setMainData(campaign);
          // setNotes(notes);
        }
      } catch (e) {
        closeSingleViewHandler();
        console.error(e);
      }
    };
    if (!Object.keys(mainData).length || mainData.id !== id) {
      fetchData();
    }
  }, [id, closeSingleViewHandler]);

  const updateInputHandler = async (data) => {
    const { key, value } = data;
    if (!value) {
      setErrorState({ key, msg: "Is required" });
      return;
    }

    if (key === "email") {
      if (!FeUtils.validateEmail(value)) {
        setErrorState({ key, msg: "Enter valid email" });
        return;
      }
    }

    let newValue = value;
    if (["annualRevenue", "noOfEmployees"].includes(key)) {
      newValue = Number(value);
    }
    setMainData({ ...mainData, [key]: newValue });
    try {
      await LeadsService.updateLead(id, { [key]: newValue });
      if (type === 'lead') {
        dispatch(updateLead({ key: key, value: newValue, id }));
      } else if (type === 'account') {
        dispatch(updateAccount({ key: key, value: newValue, id }));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const updateSelectHandler = async (data) => {
    const { name, value } = data.target;
    let valueData = value;
    if (name === "rating") {
      valueData = Number(value);
    }

    if (name === "owner") {
      let ownerData = {};
      users.forEach((item) => {
        if (item?.userId === value) {
          const { userId, ...rest } = item;
          ownerData = { id: userId, ...rest };
        }
      });
      setMainData({ ...mainData, [name]: ownerData });
      (type === 'lead')
        ? dispatch(updateLead({ key: name, value: ownerData }))
        : dispatch(updateAccount({ key: name, value: ownerData}));

    } else {
      setMainData({ ...mainData, [name]: valueData });
      (type === 'lead')
        ? dispatch(updateLead({ key: name, value: valueData, id }))
        : dispatch(updateAccount({ key: name, value: valueData, id }));
    }
    try {
      await LeadsService.updateLead(id, { [name]: valueData });
    } catch (e) {
      console.log(e);
    }
  };

  const inputEditingStatusHandler = (status) => {
    setIsEditing(status);
    status && setErrorState({});
  };

  const closeClickHandler = () => {
    closeSingleViewHandler();
  };

  const convertClickHandler = async () => {
    try {
      await AccountsService.convertLeadToAccount(id);
      updateParentComponent();
      closeSingleViewHandler();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="singleView">
      {Object.keys(mainData).length ? (
        <React.Fragment>
          <header className="singleView__header">
            <div>
              <div className="singleView__avatar">
                <Avatar src="assets/img/profile-default.png" />
              </div>
              <div className="singleView__title2">
                <h4>{mainData?.name}</h4>
                <span>{mainData?.companyName}</span>
              </div>
            </div>

            <div className="singleView__header-last">
              {type === 'lead' && (
                <button
                  color="primary"
                  variant="contained"
                  onClick={convertClickHandler}
                  className="convertBtn"
                >
                  Convert
                </button>
              )}
              <div className="singleView__close">
                <IconButton aria-label="close dialog" onClick={closeClickHandler}>
                  <CloseIcon />
                </IconButton>
              </div>
            </div>

          </header>

          <div className="singleView__plane">
            <header>
              <div>Details</div>
            </header>

            <div
              className={`singleView__plane-inside ${isEditing && "editing"}`}
            >
              <div className="singleView__plane-row1">
                <span>Name</span>
                <TextFieldElement
                  id="name"
                  state={mainData}
                  updateHandler={updateInputHandler}
                  editing={inputEditingStatusHandler}
                  error={errorState}
                />
              </div>

              <div className="singleView__plane-row1">
                <span>Company Name</span>
                <TextFieldElement
                  id="companyName"
                  state={mainData}
                  updateHandler={updateInputHandler}
                  editing={inputEditingStatusHandler}
                />
              </div>

              <div className="singleView__plane-row1">
                <span>Email</span>
                <TextFieldElement
                  id="email"
                  state={mainData}
                  updateHandler={updateInputHandler}
                  editing={inputEditingStatusHandler}
                  error={errorState}
                />
              </div>

              <div className="singleView__plane-row1">
                <span>Mobile</span>
                <TextFieldElement
                  id="mobile"
                  state={mainData}
                  updateHandler={updateInputHandler}
                  editing={inputEditingStatusHandler}
                  error={errorState}
                />
              </div>

              <div className="singleView__plane-row1">
                <span>Address</span>
                <TextFieldElement
                  id="address"
                  state={mainData}
                  updateHandler={updateInputHandler}
                  editing={inputEditingStatusHandler}
                  multiline={true}
                  error={errorState}
                />
              </div>

              <div className="singleView__plane-row1">
                <span>Lead Owner </span>
                <div className="text-field-element">
                  {!!users && !!users?.length && (
                    <Select
                      id="owner"
                      name="owner"
                      value={mainData?.owner?.id || ''}
                      onChange={updateSelectHandler}
                      disabled={type === 'account'}
                    >
                      {users.map((item, index) => (
                        <MenuItem
                          value={item?.userId}
                          key={index}
                        >{`${item?.firstName} ${item?.lastName}`}</MenuItem>
                      ))}
                    </Select>
                  )}
                </div>
              </div>

              <div className="singleView__plane-row1">
                <span>Lead Source</span>
                <TextFieldElement
                  id="source"
                  state={mainData}
                  updateHandler={updateInputHandler}
                  editing={inputEditingStatusHandler}
                />
              </div>

              <div className="singleView__plane-row1">
                <span>Lead Status</span>
                <div className="text-field-element">
                  {!!status && !!status?.length && (
                    <Select
                      id="status"
                      name="status"
                      value={mainData?.status}
                      onChange={updateSelectHandler}
                    >
                      {status.map((item, index) => (
                        <MenuItem value={item.status} key={index}>
                          {item.status}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </div>
              </div>

              {toggle && (
                <div>
                  <div className="singleView__plane-row1">
                    <span>Title</span>
                    <TextFieldElement
                      id="title"
                      state={mainData}
                      updateHandler={updateInputHandler}
                      editing={inputEditingStatusHandler}
                    />
                  </div>

                  <div className="singleView__plane-row1">
                    <span>Industry</span>
                    <TextFieldElement
                      id="industry"
                      state={mainData}
                      updateHandler={updateInputHandler}
                      editing={inputEditingStatusHandler}
                    />
                  </div>

                  <div className="singleView__plane-row1">
                    <span>Annual Revenue</span>
                    <TextFieldElement
                      id="annualRevenue"
                      state={mainData}
                      updateHandler={updateInputHandler}
                      editing={inputEditingStatusHandler}
                      type="number"
                      min="0"
                      pattern="[0-9]{10}"
                    />
                  </div>

                  <div className="singleView__plane-row1">
                    <span>No Of Employees</span>
                    <TextFieldElement
                      id="noOfEmployees"
                      state={mainData}
                      updateHandler={updateInputHandler}
                      editing={inputEditingStatusHandler}
                      type="number"
                      min="0"
                      pattern="[0-9]{10}"
                    />
                  </div>

                  <div className="singleView__plane-row1">
                    <span>Fax</span>
                    <TextFieldElement
                      id="fax"
                      state={mainData}
                      updateHandler={updateInputHandler}
                      editing={inputEditingStatusHandler}
                      error={errorState}
                    />
                  </div>

                  <div className="singleView__plane-row1">
                    <span>Secondary Email</span>
                    <TextFieldElement
                      id="secondaryEmail"
                      state={mainData}
                      updateHandler={updateInputHandler}
                      editing={inputEditingStatusHandler}
                      error={errorState}
                    />
                  </div>

                  <div className="singleView__plane-row1">
                    <span>Website</span>
                    <TextFieldElement
                      id="website"
                      state={mainData}
                      updateHandler={updateInputHandler}
                      editing={inputEditingStatusHandler}
                      error={errorState}
                    />
                  </div>

                  <div className="singleView__plane-row1">
                    <span>Skype ID</span>
                    <TextFieldElement
                      id="skypeId"
                      state={mainData}
                      updateHandler={updateInputHandler}
                      editing={inputEditingStatusHandler}
                    />
                  </div>

                  <div className="singleView__plane-row1">
                    <span>Twitter</span>
                    <TextFieldElement
                      id="twitter"
                      state={mainData}
                      updateHandler={updateInputHandler}
                      editing={inputEditingStatusHandler}
                    />
                  </div>

                  <div className="singleView__plane-row1">
                    <span>Rating</span>
                    <Rating
                      id="rating"
                      name="rating"
                      value={mainData?.rating || 0}
                      onChange={updateSelectHandler}
                    />
                  </div>

                  <div className="singleView__plane-row1">
                    <span>Description</span>
                    <TextFieldElement
                      id="description"
                      state={mainData}
                      updateHandler={updateInputHandler}
                      editing={inputEditingStatusHandler}
                      multiline={true}
                    />
                  </div>
                </div>
              )}

              <div className="singleView__plane-row1">
                <button onClick={() => setToggle(!toggle)}>
                  {!toggle && <span>Show </span>}
                  {toggle && <span>Hide </span>}
                  Additional Details
                </button>
              </div>
            </div>
          </div>

          <NotesComponent
            notes={notes}
            id={id}
          />

          <ContactFormComponent
            id={id}
          />
        </React.Fragment>
      ) : (
        <BackdropElement />
      )}
    </div>
  );
};

export default AccountSingleView;
