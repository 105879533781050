import React, { useEffect, useState } from "react";
import "./SingleViewCampaign.scss";
import TextFieldElement from "../TextFieldElement/TextFieldElement";
import BackdropElement from "../BackdropElement/BackdropElement";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CampaignService from "../../services/campaign.service";
import NotesComponent from '../../components/NotesComponent/NotesComponent';
import FeUtils from "@fe-sales/common-utils/fe-utils";

const AccountSingleView = (props) => {
  const { id, closeSingleViewHandler } = props;

  const [mainData, setMainData] = useState({});
  const [notes, setNotes] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [, setErrorState] = useState({});

  const fetchData = async () => {
    if (!Object.keys(mainData).length || mainData.id !== id) {
      try {
        setMainData({});
        setNotes([]);

        // const { campaign, notes } = await CampaignService.getCampaign(id);
        const campaign = await CampaignService.getCampaign(id);

        setMainData(campaign);
        setNotes(notes);

      } catch (e) {
        closeSingleViewHandler();
        console.error(e);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [id, closeSingleViewHandler]);

  const GFG_Fun = (obj) => {
    const params = Object.keys(obj).map((key) => {
      return `utm_${key}=${obj[key].replaceAll(/\s+/g, '%20')}`;
    }).join('&');
    return params;
  }

  const prepareCampaigeUrl = (values) => {
    if (values?.websiteURL) {
      const includedFields = ['source', 'medium', 'name', 'term', 'content'];
      if (FeUtils.isValidUrl(values?.websiteURL)) {
        const valuesObject = {...values};
        Object.entries(valuesObject).forEach(([key, value]) => {
          if(!includedFields.includes(key) || !value) {
            delete valuesObject[key];
          }
        })
        return `${values?.websiteURL}?${GFG_Fun(valuesObject)}`;
      }
    }

    return '';
  }

  const updateInputHandler = async (data) => {
    const { key, value } = data;
    if (!value) {
      setErrorState({ key, msg: "Is required" });
      return;
    }

    let values = mainData;
    values[key] = value;
    values.campaignURL = prepareCampaigeUrl(values);

    try {
      await CampaignService.updateCampaignItem(id, values);
      fetchData();
    } catch (e) {
      console.log(e);
    }
  };

  const inputEditingStatusHandler = (status) => {
    setIsEditing(status);
    status && setErrorState({});
  };

  const closeClickHandler = () => {
    closeSingleViewHandler();
  };


  return (
    <div className="singleViewCampaign">
      {Object.keys(mainData).length ? (
        <React.Fragment>
          <div className="singleViewCampaign__close">
            <IconButton aria-label="close dialog" onClick={closeClickHandler}>
              <CloseIcon />
            </IconButton>
          </div>
          <header className="singleViewCampaign__header">
            <div>
              <div className="singleViewCampaign__title2">
                <h4>{mainData?.name}</h4>
              </div>
            </div>

            <div>
            </div>
          </header>

          <div className="singleViewCampaign__plane">
            <header>
              <div>Details</div>
            </header>

            <div
              className={`singleViewCampaign__plane-inside ${isEditing && "editing"}`}
            >
              <div className="singleViewCampaign__plane-row1">
                <span>Campaign Name</span>
                <TextFieldElement
                  id="name"
                  state={mainData}
                  updateHandler={updateInputHandler}
                  editing={inputEditingStatusHandler}
                />
              </div>

                <div className="singleViewCampaign__plane-row1">
                    <span>Campaign Type</span>
                    <TextFieldElement
                        id="type"
                        state={mainData}
                        updateHandler={updateInputHandler}
                        editing={inputEditingStatusHandler}
                    />
                </div>

                <div className="singleViewCampaign__plane-row1">
                    <span>Start Date</span>
                    <TextFieldElement
                        id="startDate"
                        state={mainData}
                        updateHandler={updateInputHandler}
                        editing={inputEditingStatusHandler}
                    />
                </div>

                <div className="singleViewCampaign__plane-row1">
                    <span>End Date</span>
                    <TextFieldElement
                        id="endDate"
                        state={mainData}
                        updateHandler={updateInputHandler}
                        editing={inputEditingStatusHandler}
                    />
                </div>

                <div className="singleViewCampaign__plane-row1">
                    <span>Owner</span>
                    <TextFieldElement
                        id="owner"
                        state={mainData}
                        updateHandler={updateInputHandler}
                        editing={inputEditingStatusHandler}
                    />
                </div>

                <div className="singleViewCampaign__plane-row1">
                    <span>Status</span>
                    <TextFieldElement
                        id="status"
                        state={mainData}
                        updateHandler={updateInputHandler}
                        editing={inputEditingStatusHandler}
                    />
                </div>

                <div className="singleViewCampaign__plane-row1">
                    <span>Description</span>
                    <TextFieldElement
                        id="description"
                        state={mainData}
                        updateHandler={updateInputHandler}
                        editing={inputEditingStatusHandler}
                        multiline="true"
                    />
                </div>

                <div className="singleViewCampaign__plane-row1">
                    <span>Website URL</span>
                    <TextFieldElement
                        id="websiteURL"
                        state={mainData}
                        updateHandler={updateInputHandler}
                        editing={inputEditingStatusHandler}
                        multiline="true"
                    />
                </div>

                <div className="singleViewCampaign__plane-row1">
                    <span>Campaign Source</span>
                    <TextFieldElement
                        id="source"
                        state={mainData}
                        updateHandler={updateInputHandler}
                        editing={inputEditingStatusHandler}
                    />
                </div>

                <div className="singleViewCampaign__plane-row1">
                    <span>Campaign Medium</span>
                    <TextFieldElement
                        id="medium"
                        state={mainData}
                        updateHandler={updateInputHandler}
                        editing={inputEditingStatusHandler}
                    />
                </div>

                <div className="singleViewCampaign__plane-row1">
                    <span>Campaign Term</span>
                    <TextFieldElement
                        id="term"
                        state={mainData}
                        updateHandler={updateInputHandler}
                        editing={inputEditingStatusHandler}
                    />
                </div>

                <div className="singleViewCampaign__plane-row1">
                    <span>Campaign Content</span>
                    <TextFieldElement
                        id="content"
                        state={mainData}
                        updateHandler={updateInputHandler}
                        editing={inputEditingStatusHandler}
                        multiline="true"
                    />
                </div>

                <div className="singleViewCampaign__plane-row1">
                    <span>Campaign URL</span>
                    <TextFieldElement
                        readOnly
                        id="campaignURL"
                        state={mainData}
                        updateHandler={updateInputHandler}
                        editing={inputEditingStatusHandler}
                        multiline="true"
                    />
                </div>

            </div>
          </div>

          <NotesComponent
            notes={notes}
            id={id}
          />


        </React.Fragment>
      ) : (
        <BackdropElement />
      )}
    </div>
  );
};

export default AccountSingleView;
