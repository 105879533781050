import React, {useEffect, useState} from "react";
import "./AssignPhoneFormComponent.scss";
import {Button, TextField, Select, MenuItem} from '@material-ui/core';
import SipService from '../../services/sip.service';

const AssignPhoneFormComponent = (props) => {
  const { userId } = props;
  const [assignForm, setAssignForm] = useState(true);
  const [assignValue, setAssignValue] = useState({});
  const [phoneList, setPhoneList] = useState([]);
  const [domainsList, setDomainsList] = useState([]);
  const [releaseForm, setReleaseForm] = useState(true);

  const [phone, setPhone] = useState("");
  const [domain, setDomain] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await SipService.getAssignedPhone(userId);
        setAssignValue(data);

        const phonesList = await SipService.getPhoneList();
        setPhoneList(phonesList);

        const domainsList = await SipService.getSipDomains();
        setDomainsList(domainsList);
      } catch (e) {
        console.error(e);
      }
    };
    fetchData();
  }, [userId, releaseForm]);

  const handleReleasePhone = async (phone) => {
    try {
      await SipService.releasePhone(phone);
      setAssignValue(false);
    } catch (e) {
      console.error(e);
    }
  };

  const handleSubmit = async () => {
    const data = {
      userId,
      phone,
      sipPassword: password,
      emailForSipVoiceMail: email,
      domain
    }

    try {
      await SipService.assignPhone(data);
      setAssignValue(false);
    } catch (e) {
      console.error(e);
    }
  };

  const renderAssignForm = () => {
    return (
      <div>
        {assignForm && (
          <button className="assigned__btnText" onClick={() => setAssignForm(false)}>
            Assign phone
          </button>
        )}
        {!assignForm && (
          <div className="assigned__frame">
            <form className="assigned__form">
              <div className="assigned__row">
                <span>Phone</span>
                <div className="assigned__select">
                  {phoneList && phoneList.length && (
                    <Select
                      id="phoneList"
                      name="phoneList"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    >
                      {phoneList.map((item, index) => (
                        <MenuItem value={item.phoneNumber} key={index}>
                          {item.phoneNumber}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </div>
              </div>
              <div className="assigned__row">
                <span>SIP Domain</span>
                <div className="assigned__select">
                  {domainsList && domainsList.length && (
                    <Select
                      id="domainsList"
                      name="domainsList"
                      value={domain}
                      onChange={(e) => setDomain(e.target.value)}
                    >
                      {domainsList.map((item, index) => (
                        <MenuItem value={item.domain} key={index}>
                          {item.domain}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </div>
              </div>
              <TextField
                id="email"
                label={"Email For Voice Mail"}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                id="password"
                label={"SIP Password"}
                value={password}
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <div className="assigned__btnWrap">
                <Button variant="contained" onClick={() => handleSubmit()}>
                  Assign Phone
                </Button>
                <Button className="cancel" variant="contained" onClick={() => setAssignForm(true)}>
                  Cancel
                </Button>
              </div>
            </form>
          </div>
        )}
      </div>
    )
  }

  const renderReleaseForm = () => {
    return (
      <div className="assigned__frame">
        {releaseForm && (
          <form className="assigned__form">
            <h3>Assigned Phone</h3>
            <TextField
              disabled
              id="phone"
              label={"Phone"}
              value={assignValue?.phone}
            />
            <TextField
              disabled
              id="sipDomain"
              label={"SIP Domain"}
              value={assignValue?.domain}
            />
            <TextField
              disabled
              id="assignedPhone"
              label={"Email For Voice Mail"}
              value={assignValue?.email}
            />
            <div className="assigned__btnWrap">
              <Button variant="contained" onClick={() => setReleaseForm(false)}>
                Release Phone
              </Button>
            </div>
          </form>
        )}
        {!releaseForm && (
          <div>
            <p>Are you sure you want to release this phone number from the user?</p>
            <p>User will not be able to make or receive.</p>
            <p>Calls on this SIP domain.</p>
            <div className="assigned__btnWrap">
              <Button variant="contained" onClick={() => handleReleasePhone(assignValue?.phone)}>
                Release
              </Button>
              <Button className="cancel" variant="contained" onClick={() => setReleaseForm(true)}>
                Cancel
              </Button>
            </div>
          </div>
        )}
      </div>
    )
  }

  return (
    <>
      {Object.keys(assignValue)?.length ? renderReleaseForm() : renderAssignForm()}
    </>
  )
};

export default AssignPhoneFormComponent;
