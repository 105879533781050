import React, { useEffect, useState } from "react";
import PageLayout from "../../components/page-layout/page-layout";
import AccountService from "../../services/accounts.service";
import TableElement from "../../components/TableElement/TableElement";
import { useDispatch, useSelector } from "react-redux";
import { accountsState, addAccounts } from "./accounts-slice";
import BackdropElement from "../../components/BackdropElement/BackdropElement";
import EmptyListElement from "../../components/EmptyListElement/EmptyListElement";
import SingleView from "../../components/singe-view/SingleView";
import Pagination from '../../components/Pagination/Pagination';
import GlobalAppConstant from '../../constants/globalAppConstant';

const AccountsComponent = () => {
  const tableCells = {
    name: { name: "Account Name" },
    email: { name: "Email" },
    createdOn: { name: "Created On", extra: "dates" },
    owner: { name: "Created By" },
  };

  const dispatch = useDispatch();

  const tableData = useSelector(accountsState);

  const [dataLoaded, setDataLoaded] = useState(false);

  const [singleCampaign, setSingleCampaign] = useState("");

  const [singleAccountState, setSingleAccountState] = useState(false);

  const [paginationState, setPaginationState] = useState(0);
  const [updateAccounts, setUpdateAccounts] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
        const filterData = {
          filter: {
            limit: GlobalAppConstant.defaultTableRows,
            skip: paginationState
          }
        }
        try {
          const response = await AccountService.getAccountsList(filterData);
          dispatch(addAccounts(response));
          setDataLoaded(true);
        } catch (e) {
          console.error(e);
          setDataLoaded(true);
        }
    };

    fetchData();
  }, [updateAccounts, paginationState]);

  /**
   * Table Row Click Handler.
   * @param id
   */
  const tableRowClickHandler = (id) => {
    setSingleCampaign(id);
    setSingleAccountState(true);
  };

  const paginationClickHandler = async (key) => {
    if (key === 'next') {
      setPaginationState(paginationState + GlobalAppConstant.defaultTableRows)
    }

    if (key === 'prev') {
      setPaginationState(paginationState - GlobalAppConstant.defaultTableRows)
    }
  };

  return (
    <PageLayout>
      {singleAccountState && (
        <SingleView
          id={singleCampaign}
          type="account"
          closeSingleViewHandler={() => setSingleAccountState(false)}
          updateAccounts={() => setUpdateAccounts(!updateAccounts)}
        />
      )}
      {dataLoaded ? (
        Object.keys(tableData).length ? (
          <>
            <TableElement
              tableData={tableData}
              tableRowClickHandler={tableRowClickHandler}
              tableCells={tableCells}
            />
            <Pagination
              paginationState={paginationState}
              tableData={tableData}
              paginationClickHandler={paginationClickHandler}
            />
          </>
        ) : (
          <EmptyListElement text="No Accounts Found" />
        )
      ) : (
        <BackdropElement />
      )}
    </PageLayout>
  );
};

export default AccountsComponent;
