import React from "react";
import "./EmptyListElement.scss";

const EmptyListElement = (props) => {
  const { text = "No data" } = props;

  return <div className="empty-list-element">{text}</div>;
};

export default EmptyListElement;
