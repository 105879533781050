import React, {useEffect, useState} from "react";
import { Grid } from "@material-ui/core";
import "./login.scss";
import {AlertSnackbar, LoginPage} from "@fe-sales/common-ui";
import logo from "./../../assets/img/logo.svg";
import urls from "../../routes/urls";
import AuthService from "../../services/auth.service";
import {LOGIN_ENDPOINT, WORKCENTER_LINK} from "../../constants/apiEnpoints.constant";
import { getJwtToken } from "../../bridge/jwtToken.bridge";
import ProfileService from "../../services/profile.service";
import { updateAvatar, updateProfile } from "../../slices/profile-slice";
import { setRedirectState } from "../../slices/app-slice";
import { useDispatch } from "react-redux";
import FeUtils from "@fe-sales/common-utils/fe-utils";

const LoginComponent = ({ history, location }) => {

  const dispatch = useDispatch();

  useEffect(() => {
    console.log('Sales Login Test 1');
    if (getJwtToken()) {
      history.push({
        pathname: urls.dashboard,
      });
    }
  }, []);

  const [alert, setAlert] = useState('')

  const loginHandler = async (userCredentials) => {
    const { user, password } = userCredentials;

    try {
      const loginResponse = await AuthService.loginApi(LOGIN_ENDPOINT, { email: user, password });
      console.log('loginResponse : ', loginResponse);
      //Added to Check the User Role and Navigate to WC.
      if (loginResponse?.code == 200) {
        const profileResponse = await ProfileService.getProfile();
        const { redirect } = await AuthService.handleAppRedirect(profileResponse);
        dispatch(setRedirectState(redirect));
        if (!redirect) {
          dispatch(updateProfile(profileResponse));
          const profileResponseAvatar = await ProfileService.getProfileAvatar();
          if (profileResponseAvatar !== "No media file found.") {
            const image = await FeUtils.toBase64(profileResponseAvatar);
            dispatch(updateAvatar(image));
          }
          history.push(urls.dashboard);
        }
      } else {
        setAlert(loginResponse?.message?.web);
      }
    } catch (e) {
      console.log(e);
      return setAlert(e.message);
    }
  };

  const forgotPasswordHandler = () => {
    history.push(urls.public.forgotPassword);
  };

  return (
    <>
      <AlertSnackbar
        showAlert={!!alert}
        type="alert"
        timer={3000}
        color="error"
        message={alert}
        UserAction={() => setAlert('')}
      />
      <div className="containerAuth">
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={10} sm={10} md={6} lg={4} className="login-container">
            <LoginPage
              /*forgotPasswordHandler={forgotPasswordHandler}*/
              title={"Sales & Marketing"}
              companyLogo={logo}
              styles={{
                logoStyle: { width: "100px", height: "100px" },
                titleStyle: {
                  color: "#FFF",
                  fontSize: "1.6rem",
                  padding: "10px",
                },
                dialogStyle: { backgroundColor: "rgba(255, 255, 255, 0.2)" },
              }}
              onLogin={loginHandler}
            />
          </Grid>
        </Grid>
      </div>
      </>
  );
};

export default LoginComponent;
