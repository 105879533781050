/**
 * @file
 * Contains Urls config.
 */

export default {
  root: "/",

  // Public links mapping.
  public: {
    // Login page.
    login: "/login",

    // Forgot password page.
    forgotPassword: "/forgot-password",

    // sing up page.
    signUp: "/create-account",

    // Subuser sing up page.
    signUpUser: "/signup-user",
  },

  // Dashboard page.
  dashboard: "/dashboard",

  // leads page.
  leads: "/leads",

  // campaign page.
  campaign: "/campaign",

  // Accounts page.
  accounts: "/accounts",

  // User page.
  users: "/users",

  // Profile page.
  profile: "/profile",

  // Logout page.
  logout: "/logout",

  // Call Logs page.
  callLogs: "/call-logs",

  // Call Logs page.
  phoneList: "/phone-list",

  // Call Recordings page.
  callRecordings: "/call-recordings",
};
