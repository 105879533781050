import React, { useEffect, useState } from "react";
import './phoneList.scss';
import PageLayout from '../../components/page-layout/page-layout';
import TableElement from '../../components/TableElement/TableElement';
import EmptyListElement from '../../components/EmptyListElement/EmptyListElement';
import BackdropElement from '../../components/BackdropElement/BackdropElement';
import PhoneService from '../../services/phone.service';

const PhoneListScreen = (props) => {
  const tableCells = {
    friendlyName: { name: "Name" },
    phoneNumber: { name: "Phone Number" },
    status: { name: "Status" },
    email: { name: "Voice Mail", innerObject: 'userSIP' },
    provisionDate: { name: "Provision Date", extra: "dates", format: "DD MMM YYYY", innerObject: 'userSIP' },
  };

  const [dataLoaded, setDataLoaded] = useState(false);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setDataLoaded(false);
        const response = await PhoneService.getPhoneList();
        setTableData(response || {});
      } catch (e) {
        console.error(e);
      } finally {
        setDataLoaded(true);
      }
    };

    fetchData();
  }, []);

  return (
    <PageLayout>
      {dataLoaded ? (
        Object.keys(tableData).length ? (
          <TableElement
            tableData={tableData}
            tableCells={tableCells}
          />
        ) : (
          <EmptyListElement text="No Phones Found" />
        )
      ) : (
        <BackdropElement />
      )}
    </PageLayout>
  )
};

export default PhoneListScreen;
