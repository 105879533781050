import { createSlice, current } from "@reduxjs/toolkit";

export const callRecordingsSlice = createSlice({
  name: "callRecordingsSlice",
  initialState: [],
  reducers: {
    addCallRecordings: (state, data) => {
      return data.payload;
    },
  },
});

export const callRecordingsState = (state) => state.callRecordingsSlice;

export const { addCallRecordings } = callRecordingsSlice.actions;

export default callRecordingsSlice.reducer;
