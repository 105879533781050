import React from "react";
import "./TableElement.scss";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import FeUtils from "@fe-sales/common-utils/fe-utils";
import { useDispatch } from 'react-redux';
import { updateUrl } from '../../slices/app-slice';
import useWindowSize from '../../hooks/useWindowSize';
import MobileTableView from './MobileTableView/MobileTableView';

const TableElement = (props) => {
  const { tableCells, tableData, tableRowClickHandler, activeRow } = props;

  /**
   * Window size hook.
   */
  const [width,] = useWindowSize();
  const dispatch = useDispatch();

  const playAudioHandler = (url) => {
    dispatch(updateUrl(url));
  };

  const renderTableRow = (data) => {
    if (data.length) {
      return data.map((row, i) => (
        <TableRow
          key={i}
          onClick={() => !!tableRowClickHandler && tableRowClickHandler(row.id)}
          className={`${activeRow && row.id === activeRow ? "active" : ""} ${!tableRowClickHandler ? "no-event" : ""}`}
        >
          {Object.keys(tableCells).map((key, index) => {
            if (tableCells[key]?.innerObject && (tableCells[key]?.innerObject in row)) {
              if (tableCells[key].extra === "dates") {
                return (
                  <TableCell key={index}>
                    {FeUtils.formatDateTime(row[tableCells[key]?.innerObject][key])}
                  </TableCell>
                );
              }

              return <TableCell key={index}>{row[tableCells[key]?.innerObject][key]}</TableCell>;

            } else {
              if (tableCells[key]?.innerObject) {
                return (
                  <TableCell key={index} />
                );
              }

              if ((tableCells[key].extra === "dates")) {
                let format = "DD MMM YYYY, hh:mm a";
                if ("format" in tableCells[key]) {
                  format = tableCells[key].format;
                }

                return (
                  <TableCell key={index}>
                    {FeUtils.formatDateTime(row[key], format)}
                  </TableCell>
                );
              }
              if (key === "owner") {
                return (
                  <TableCell key={index}>
                    {row[key]?.firstName} {row[key]?.lastName}
                  </TableCell>
                );
              }
              if (tableCells[key].extra === "time") {
                return (
                  <TableCell key={index}>
                    {FeUtils.secondsToHms(row[key])}
                  </TableCell>
                );
              }
              if (key === "roles") {
                  return <TableCell key={index}>{
                      row?.roles?.length
                      ? (
                      row?.roles.map((item, n) => {
                      return (<div key={n}>{item.name}</div>)
                  })
                      )
                      : '-'
                  }</TableCell>;
              }
              if (key === "recording") {
                return <TableCell key={index}>{
                  row?.recordings?.length
                    ? (
                        row?.recordings.map((item, m) => {
                          return (<i className="icons8-circled-play play-icon" key={m} onClick={() => playAudioHandler(item)}/>)
                        })
                      )
                    : '-'
                }</TableCell>;
              }

              return <TableCell key={index}>{row[key]}</TableCell>;
            }

          })}
        </TableRow>
      ));
    }
  };

  return (
    <div className="t-cont">
      {width > 667 ? (
        <div className="table">
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {Object.keys(tableCells).map((key, index) => (
                    <TableCell key={index}>{tableCells[key].name}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>{renderTableRow(tableData)}</TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : (
        <MobileTableView
          tableCells={tableCells}
          tableData={tableData}
          tableRowClickHandler={tableRowClickHandler}
          activeRow={activeRow}
          playAudioHandler={playAudioHandler}
        />
      )}
    </div>
  );
};

export default TableElement;
