import React, { useEffect, useState } from "react";
import "./NotesComponent.scss";
import { Button, TextField } from '@material-ui/core';
import FeUtils from '@fe-sales/common-utils/fe-utils';
import LeadsService from '../../services/leads.service';
import { useSelector } from 'react-redux';
import { profileStateData } from '../../slices/profile-slice';
import { leadsState } from '../../pages/leads/leads-slice';

const NotesComponent = (props) => {
  const { id, notes } = props;

  const profileData = useSelector(profileStateData);

  const { users } = useSelector(leadsState);

  const [toggleForm, setToggleForm] = useState(false);
  const [noteValue, setNoteValue] = useState("");
  const [notesData, setNotesData] = useState([]);

  useEffect(() => {
    setNotesData(notes);
  }, [notes])

  const addNoteBtn = () => {
    setToggleForm(!toggleForm);
  };

  const noteValueChangeHandler = (e) => {
    setNoteValue(e.target.value);
  };

  const addNoteHandler = async (e) => {
    e.preventDefault();
    const { userId, email, firstName, lastName } = profileData || {};
    try {
      const res = await LeadsService.setNote(id, { description: noteValue });
      setToggleForm(false);
      setNoteValue("");
      setNotesData([
        ...notesData,
        {
          ...res,
          owner: { id: userId, email, firstName, lastName },
        },
      ]);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="notesView__plane">
      <header>
        <div>Notes</div>
        <div>
          <button onClick={addNoteBtn}>+ Add Note</button>
        </div>
      </header>

      <div className="notesView__plane-inside">
      {toggleForm && (
        <form
          className="notesView__form notes"
          onSubmit={addNoteHandler}
        >
          <TextField
            required
            label="Enter Note"
            className="input2 note-input"
            id="note"
            value={noteValue}
            onChange={noteValueChangeHandler}
            autoFocus={toggleForm}
          />
          <Button variant="contained" type="submit">
            Add
          </Button>
        </form>
      )}
      {!!notesData?.length &&
        notesData.slice(0).reverse().map((item, index) => (
          <div className="notesView__plane-row2" key={index}>
            <span>{item?.description}</span>
            <span>
              {item?.recordCreated &&
              `${FeUtils.formatDateTime(item.recordCreated)}`
              }
              {item?.owner?.firstName && ` - ${item?.owner?.firstName}`}
              {item?.owner?.lastName && ` ${item?.owner?.lastName}`}
            </span>
          </div>
        ))}
    </div>
  </div>
  )
};

export default NotesComponent;
