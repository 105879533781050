import moment from "moment";
import { parsePhoneNumberFromString } from "libphonenumber-js";

function arrayBufferToBase64(buffer) {
  return new Promise((resolve) => {
    let binary = "";
    let bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => (binary += String.fromCharCode(b)));

    resolve(window.btoa(binary));
  });
}

class FeUtils {
  emailRegex = new RegExp(
    /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,9}|[0-9]{1,3})(\]?)$/
  );

  validateEmail(_email) {
    return this.emailRegex.test(_email);
  }

  validatePassword(_password, digits = 8) {
    const pattern = `^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z]).{${digits},}$`;
    const passwordRegex = new RegExp(pattern);
    return passwordRegex.test(_password);
  }

  async toBase64(data) {
    const base64Flag = "data:image/jpeg;base64,";
    let imageStr = await arrayBufferToBase64(data);
    return base64Flag + imageStr;
  }

  fileToDataUri(image) {
    return new Promise((res) => {
      const reader = new FileReader();
      const { type, name, size } = image;
      reader.addEventListener("load", () => {
        res({
          base64: reader.result,
          name: name,
          type,
          size: size,
        });
      });
      reader.readAsDataURL(image);
    });
  }

  formatDateTime(date, format = "DD MMM YYYY, LT") {
    return moment(date).format(format);
  }

  daysAgoDate(days, format = "YYYY-MM-DD") {
    return moment().subtract(days,'d').format(format);
  }


  secondsToHms(d) {
    d = Number(d);
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    const s = Math.floor((d % 3600) % 60);

    const hDisplay = h > 0 ? `${h} h ` : "";
    const mDisplay = m > 0 ? `${m} min ` : "";
    const sDisplay = s > 0 ? `${s} sec` : "0sec";
    return hDisplay + mDisplay + sDisplay;
  }

  /**
   * Phone number validation.
   * @param {string} inputValue.
   * Field value.
   */
  phoneNumberValidation(inputValue) {
    const value = (inputValue || "").replace(/[`()\s-]/gi, "");
    if (inputValue && inputValue[0] !== "+") {
      return false;
    }

    if (value && value.length > 5) {
      const phoneNumber = parsePhoneNumberFromString(value);
      return phoneNumber && phoneNumber.isValid();
    }
    return false;
  }

  /**
   * Check if URL is valid.
   */
  isValidUrl(url) {
    const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(url);
  };

  /**
   * Title case Convert.
   */
  titleCaseConverter(str) {
    return str.replace(/(^|\s)\S/g, function(t) { return t.toUpperCase() });
  }

  /**
   * Create object of url params.
   * @param {string} url.
   * Url params.
   */
  getParamsFromUrl = url => {
    const regex = /[?&]([^=#]+)=([^&#]*)/g;
    let params = {},
      match;

    while((match = regex.exec(url)) !== null) {
      params[match[1]] = match[2];
    }

    return params;
  };

  rolesRule = (roles = []) => {
    const rolesMapping = {
      sales: ["crm-admin", "crm-user"],
      workcenter: ["workcenter-admin", "workcenter-super-user", "workcenter-user"],
      analytics: ["analytics-admin", "analytics-user"],
    }

    const listArray = roles?.map((role) => {
      let keys = [];
      Object.entries(rolesMapping).forEach(([key, value]) => {
        if (value?.includes(role)) keys.push(key);
      })
      return keys;
    });
    return [...new Set([].concat.apply([], listArray))];
  }
}

const feUtils = new FeUtils();

export default feUtils;
