import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import PageLayout from "../../components/page-layout/page-layout";
import CampaignListScreen from "../../components/campaignList/campaignList";
import CampaignTrackView from "../../components/campaign-track-view/campaign-track-view";
import { Tabs, Tab, Button } from "@material-ui/core";
import "./campaign.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  addCampaign,
  campaignState,
  addNewCampaignWindowState,
  addNewCampaignWindow,
} from "./campaign-slice";
import CampaignService from "../../services/campaign.service";
import CampaignAddNewView from '../../components/CampaignAddNewView/CampaignAddNewView';
import BackdropElement from '../../components/BackdropElement/BackdropElement';

const CampaignComponent = () => {
  const dispatch = useDispatch();

  const [dataLoaded, setDataLoaded] = useState(false);

  const campaignData = useSelector(campaignState);
  const addCampaignForm = useSelector(addNewCampaignWindowState);

  const [openAlert, setOpenAlert] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setDataLoaded(false);
      try {
        const response = await CampaignService.getCampaignList();
        dispatch(addCampaign(response|| {}));
      } catch (e) {
        console.error(e);
      } finally {
        setDataLoaded(true);
      }
    };

    fetchData();
  }, []);

  const handleAlertClick = () => {
    setOpenAlert(true);
  };

  const handleAlertClose = () => {
    setOpenAlert(false);
  };

  const [currentCampaignTab, setCurrentCampaignTab] = useState("track");

  const [value, setValue] = useState(0);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && children}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  const handleChange = (event, newValue) => {
    const tabName = (index) => {
      switch (index) {
        case 0:
          return "design";
        case 1:
          return "track";
        default:
          return "track";
      }
    };
    setCurrentCampaignTab(tabName(newValue));
    setValue(newValue);
  };

  return (
    <PageLayout>
      {addCampaignForm && (
        <CampaignAddNewView closeAddUserHandler={() => dispatch(addNewCampaignWindow(false))} />
      )}

      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="on"
        className="tabs"
        onChange={handleChange}
      >
        <Tab label="Campaigns" />
        <Tab label="Track" />
      </Tabs>

      <TabPanel value={value} index={0}>
        <div className="campaign">
          {/*<Button variant="outlined" onClick={handleAlertClick}>
            Open error alert
          </Button>
          <AlertSnackbar
            showAlert={openAlert}
            type="alert"
            timer={3000}
            color="error"
            message="Test alert error message"
            UserAction={handleAlertCllose}
          />*/}
          {dataLoaded ? <CampaignListScreen/> : <BackdropElement />}
        </div>
      </TabPanel>

      <TabPanel value={value} index={1}>
        <CampaignTrackView
          campaign={campaignData}
        />
      </TabPanel>
    </PageLayout>
  );
};

export default CampaignComponent;
