/**
 * @file
 * Contains Private route component.
 *
 *  * Private route contains next attributes:
 *  # exact {boolean} - path matches exactly
 *  # path {string} - path
 *  # component {any} - component
 */

import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { Redirect } from "react-router";
import links from "../routes/urls";
import Bridge from "../bridge";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  profileStateData,
  updateAvatar,
  updateProfile,
} from "../slices/profile-slice";
import ProfileService from "../services/profile.service";
import BackdropElement from "../components/BackdropElement/BackdropElement";
import FeUtils from "@fe-sales/common-utils/fe-utils";
import LeadsService from "../services/leads.service";
import UsersService from "../services/users.service";
import { addStatus, addUsers, leadsState } from "../pages/leads/leads-slice";

function PrivateRoute(props) {
  const { component: Component, path, ...rest } = props;

  const dispatch = useDispatch();

  const profileData = useSelector(profileStateData);
  const { status, users } = useSelector(leadsState);

  /**
   * Is data loading flag.
   */
  const [isDataLoading, setIsDataLoading] = useState(true);

  /**
   * Auth application.
   */
  useEffect(() => {
    const fetchData = async () => {
      if (Object.keys(profileData).length <= 0 && Bridge.getJwtToken()) {
        try {
          const profileResponse = await ProfileService.getProfile();
          dispatch(updateProfile(profileResponse));

          const profileResponseAvatar = await ProfileService.getProfileAvatar();
          if (profileResponseAvatar !== "No media file found.") {
            const image = await FeUtils.toBase64(profileResponseAvatar);
            dispatch(updateAvatar(image));
          }

          setIsDataLoading(false);
        } catch (e) {
          console.error(e);
          setIsDataLoading(false);
        }
      } else {
        setIsDataLoading(false);
      }
    };

    fetchData();
  }, [profileData, dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      if (!(status && status.length)) {
        try {
          const status = await LeadsService.getLeadsStatusApi();
          dispatch(addStatus(status));
        } catch (e) {
          console.error(e);
        }
      }

      if (!(users && users.length)) {
        try {
          const users = await UsersService.getUserListByRoles();
          dispatch(addUsers(users));
        } catch (e) {
          console.error(e);
        }
      }
    };

    Object.keys(profileData)?.length > 1 && fetchData();
  }, [profileData]);

  /**
   * Render component.
   * @param {object} properties.
   * Component props.
   */
  const renderComponent = (properties) => {
    if (Object.keys(profileData)?.length) {
      if (Object.values(links.public).includes(path)) {
        return <Redirect to={links.dashboard} />;
      }
      return <Component {...properties} />;
    }
    return <Redirect to={links.public.login} />;
  };

  return (
    <React.Fragment>
      {isDataLoading ? (
        <BackdropElement />
      ) : (
        <Route {...rest} render={renderComponent} />
      )}
    </React.Fragment>
  );
}

PrivateRoute.defaultProps = {
  exact: false,
};

PrivateRoute.propTypes = {
  exact: PropTypes.bool,
  path: PropTypes.string.isRequired,
  component: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
    PropTypes.func,
    PropTypes.node,
  ]).isRequired,
};

export default PrivateRoute;
