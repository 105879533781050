/**
 * @file
 * Contains JWT token action.
 */

/**
 * Set up domain name
 * @returns string
 */
// const getCookieDomain = () => {
//   const host = window.location.host.substring(
//     window.location.host.indexOf(".")
//   );
//   const isLocalhost = host.includes("localhost");
//
//   return isLocalhost ? "localhost" : host;
// };

/**
 * Save JWT token action.
 * @param {string} token.
 * JWT token.
 */
export const saveJwtToken = (token) => {
  // Cookies.set("api_token", `Bearer ${token}`, {
  //   domain: getCookieDomain(),
  //   expires: 1 / 12,
  // });
  localStorage.setItem('access_token', `Bearer ${token}`);
};

/**
 * Get JWT token action.
 * @return {string} token.
 * JWT token.
 */
export const getJwtToken = () => {
  // return Cookies.get("api_token") || "";
  return localStorage.getItem('access_token');
}

/**
 * Get JWT token expires action.
 * @return {string} token.
 * JWT token.
 */
export const getJwtTokenExpires = () => {
  // return Cookies.get("api_token") || "";
  return localStorage.getItem('access_token_expires');
}

/**
 * Remove JWT token action.
 */
export const removeJwtToken = () => {
  // Cookies.remove("api_token", { domain: getCookieDomain() });
  localStorage.removeItem('access_token');
  localStorage.removeItem('access_token_expires');
};

/**
 * Save Jwt Token Expires action.
 */
export const saveJwtTokenExpires = (expirationTime) => {
  localStorage.setItem('access_token_expires', new Date().getTime() + ( 60 * 1000 ));
};

/**
 * Save Jwt Token Refresh action.
 */
export const saveJwtRefreshToken = (refreshToken) => {
  localStorage.setItem('refresh_token', refreshToken);
};

/**
 * Get Jwt Token Refresh action.
 */
export const getJwtRefreshToken = () => {
  return localStorage.getItem('refresh_token');
};

/**
 * Save Jwt Token Refresh action.
 */
export const saveJwtRefreshTokenExpires = (expirationTime) => {
  localStorage.setItem('refresh_token_expires', new Date().getTime() + ( expirationTime * 1000 ));
};

/**
 * Get Jwt Token Refresh action.
 */
export const getJwtRefreshTokenExpires = () => {
  return localStorage.getItem('refresh_token_expires');
};

/**
 * Remove Jwt Refresh token action.
 */
export const removeJwtRefresh = () => {
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('refresh_token_expires');
};
