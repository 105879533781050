/**
 * @file
 * Contains Login component.
 *
 * Login contains next attributes:
 *  # title {string} - Rewrite default title.
 *  # companyLogo {node} - Set up custom logo.
 *  # createAccountHandler {function} - handle 'Create account click'.
 *  # forgotPasswordHandler {function} - handle 'forgot password click'.
 *  # onLogin {function} - handle form submit.
 *  # authType {string} - autification input type - email(default), username.
 *  # styles {object} - Rewrite default styles. Contain of three keys - logoStyle, titleStyle, dialogStyle.
 *  # error {object} - Error handling. Contain of two keys - {user, password}.
 */

import React, { useEffect, useRef, useState } from "react";
import { TextField, Button } from "@material-ui/core";
import "./login.css";
import FeUtils from "@fe-sales/common-utils";
import DefaultLogo from "./../assets/img/logo512.png";
import PropTypes from "prop-types";

const LoginComponent = (props) => {
  const {
    title,
    companyLogo,
    styles,
    error,
    onLogin,
    createAccountHandler,
    forgotPasswordHandler,
    authType = "email",
  } = props;

  const userNameRef = useRef(null);

  /**
   * User state.
   */
  const [user, setUser] = useState("");

  /**
   * Password state.
   */
  const [password, setPassword] = useState("");

  /**
   * User error state.
   */
  const [userError, setUserError] = useState(true);

  /**
   * Password error state.
   */
  const [passwordError, setPasswordError] = useState(true);

  const loginHandler = (e) => {
    e.preventDefault();
    if (!userError || !passwordError) {
      onLogin({
        user,
        password,
      });
    }
  };

  const updateEmailHandler = (e) => {
    if (
      (FeUtils.validateEmail(e.target.value) && authType === "email") ||
      (e?.target?.value?.length && authType === "username")
    ) {
      setUserError(false);
      setUser(e.target.value);
    } else {
      setUserError(true);
    }
  };

  useEffect(() => {
      if(userNameRef) {
        setTimeout(() => {
          const style = window.getComputedStyle(userNameRef.current)
          if (style.backgroundColor === 'rgb(232, 240, 254)') {
            setPasswordError(false);
            setUserError(false);
          }
        }, 0)
      }
  }, [userNameRef])

  const updatePassword = (e) => {
    if (e?.target?.value?.length) {
      setPassword(e.target.value);
      setPasswordError(false);
    } else {
      setPasswordError(true);
    }
  };

  return (
    <div>
      <img
        src={companyLogo ? companyLogo : DefaultLogo}
        alt=""
        className="cui_login_logo"
        style={styles?.logoStyle}
      />
      <div className="cui_login_title" style={styles?.titleStyle}>
        {title ? title : "Welcome"}
      </div>
      <div className="cui_login_dialog" style={styles?.dialogStyle}>
        <form className="cui_login_form" onSubmit={loginHandler}>
          <TextField
            required
            id="user"
            label={authType === "email" ? "Email" : "Username"}
            // value={email}
            onChange={updateEmailHandler}
            error={!!error?.user}
            helperText={!!error?.user ? error?.user : ""}
            inputRef={userNameRef}
          />
          <TextField
            required
            id="password"
            label="Password"
            type="password"
            // value={password}
            onChange={updatePassword}
            error={!!error?.password}
            helperText={!!error?.password ? error?.password : ""}
          />
          {forgotPasswordHandler && (
            <Button
              className="cui_login_forgot"
              disableElevation
              onClick={forgotPasswordHandler}
            >
              Forgot Password ?
            </Button>
          )}
          <Button
            variant="contained"
            type="submit"
            style={styles?.buttonStyle}
            disabled={userError || passwordError}
          >
            Login
          </Button>
          {createAccountHandler && (
            <Button
              className="cui_login_create"
              disableElevation
              onClick={createAccountHandler}
            >
              Create an Account
            </Button>
          )}
        </form>
      </div>
    </div>
  );
};
LoginComponent.propTypes = {
  title: PropTypes.string,
  authType: PropTypes.string,
  companyLogo: PropTypes.node,
  styles: PropTypes.object,
  error: PropTypes.object,
  onLogin: PropTypes.func,
  createAccountHandler: PropTypes.func,
  forgotPasswordHandler: PropTypes.func,
};

export default LoginComponent;
