import urls from "../routes/urls";
import { WORKCENTER_LINK, ANALYTICS_LINK } from './apiEnpoints.constant';

const links = {
  dashboard: {
    url: urls.dashboard,
    label: "Home",
    icon: "icons8-dashboard-layout",
  },
  leads: {
    url: urls.leads,
    label: "Leads",
    icon: "icons8-onboarding",
  },
  campaign: {
    url: urls.campaign,
    label: "Campaign",
    icon: "icons8-strike",
  },
  accounts: {
    url: urls.accounts,
    label: "Account",
    icon: "icons8-verified-account",
  },
  callLogs: {
    url: urls.callLogs,
    label: "Call Logs",
    icon: "icons8-call-list",
  },
  users: {
    url: urls.users,
    label: "Users",
    icon: "icons8-user-group-2",
    admin: true,
  },
  phoneList: {
    url: urls.phoneList,
    label: "Phone List",
    icon: "icons8-phone-contact",
  },
  // callRecordings: {
  //   url: urls.callRecordings,
  //   label: "Call Recordings",
  //   icon: "icons8-add-phone",
  // },

  analytics: {
    url: ANALYTICS_LINK,
    label: "Analytics",
    icon: "icons8-external-link",
    iconMini: "icons8-external-link-2",
    iconLetter: 'A',
    layout: "bottom",
    type: 'out',
  },
  workcenter: {
    url: WORKCENTER_LINK,
    label: "Workcenter",
    icon: "icons8-external-link",
    iconMini: "icons8-external-link-2",
    iconLetter: 'W',
    layout: "bottom",
    type: 'out',
  },

  profile: {
    url: urls.profile,
    label: "Profile",
    icon: "icons8-customer",
    layout: "bottom",
  },
  // install: {
  //   url: "#",
  //   label: "Install",
  //   icon: "icons8-download",
  //   layout: "bottom",
  // },
  signout: {
    url: urls.logout,
    label: "Signout",
    icon: "icons8-power-off-button",
    layout: "bottom",
  },
};

export default links;
