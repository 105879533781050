/**
 * @file
 * Contains Accounts service.
 */

import { marketingApiClient, apiClient } from "./apiClients.service";
import {
  CAMPAIGN_ENDPOINT,
  CAMPAIGN_TRACKS_ENDPOINT,
  CAMPAIGN_ENDPOINT_CREATE_TYPES,
  CAMPAIGN_ENDPOINT_CREATE_STATUS,
} from "../constants/apiEnpoints.constant";

/**
 * Get Campaign List.
 */
const getCampaignList = async () => {
  try {
    const response = await apiClient.get(CAMPAIGN_ENDPOINT);
    return response.data;
  } catch (e) {
    console.error(e);
  }
};

/**
 * Get Campaign by Id.
 */
const getCampaign = async (id) => {
  try {
    const response = await apiClient.get(`${CAMPAIGN_ENDPOINT}/${id}`);
    return response.data;
  } catch (e) {
    console.error(e);
  }
};

/**
 * Get Campaign Track List.
 */
const getCampaignTrackList = async (filter, campaign) => {
  try {
    const response = await marketingApiClient.get(
      `${CAMPAIGN_TRACKS_ENDPOINT}?filter=${filter}&campaign=${campaign}`
    );
    return response.data;
  } catch (e) {
    console.error(e);
  }
};

/**
 * Create Campaign.
 */
const createCampaignItem = async (data) => {
  try {
    await apiClient.post(
      `${CAMPAIGN_ENDPOINT}`, data
    );
  } catch (e) {
    console.error(e);
  }
};

/**
 * Update Campaign.
 */
const updateCampaignItem = async (id, data) => {
  try {
    await apiClient.put(
      `${CAMPAIGN_ENDPOINT}/${id}`, data
    );
  } catch (e) {
    console.error(e);
  }
};

/**
 * Get Types for Campaign create.
 */
const getCampaignItemTypes = async () => {
  try {
    const response = await apiClient.get(CAMPAIGN_ENDPOINT_CREATE_TYPES);
    return response.data;
  } catch (e) {
    console.error(e);
  }
};

/**
 * Get Status for Campaign create.
 */
const getCampaignItemStatus = async () => {
  try {
    const response = await apiClient.get(CAMPAIGN_ENDPOINT_CREATE_STATUS);
    return response.data;
  } catch (e) {
    console.error(e);
  }
};

export default {
  getCampaignList,
  getCampaign,
  getCampaignTrackList,
  createCampaignItem,
  updateCampaignItem,
  getCampaignItemTypes,
  getCampaignItemStatus,
};
