/**
 * @file
 * Phone service.
 */
import { apiClient } from "./apiClients.service";
import {
  PHONE_LIST,
} from "../constants/apiEnpoints.constant";

/**
 * Get Leads List.
 */
const getPhoneList = async () => {
  try {
    const response = await apiClient.get(PHONE_LIST);
    return response.data;
  } catch (e) {
    console.error(e);
  }
};

export default { getPhoneList };
