/**
 * @file
 * Contains Login component.
 *
 * AlertSnackbar contains next attributes:
 *  # showAlert {boolen}.
 *  # type {string} - component view type - alert (default), snackbar.
 *  # message {string}.
 *  # color {string} - success (default), error, warning, info.
 *  # timer {number} - delay before execute UserAction func.
 *  # UserAction {function} - handle User Action.
 */

import React, { useState, useEffect } from "react";
import "./alertSnackbar.css";
import PropTypes from "prop-types";
import { Alert, AlertTitle } from "@material-ui/lab";
import Snackbar from "@material-ui/core/Snackbar";

const AlertSnackbarComponent = (props) => {
  const {
    showAlert,
    type = "alert",
    message,
    color,
    timer = 3000,
    UserAction,
  } = props;

  useEffect(() => {
    if (timer) {
      const timeout = setTimeout(() => {
        UserAction();
      }, timer);
      return () => clearTimeout(timeout);
    }
  }, []);

  const RenderComponent = (type) => {
    if (type === "snackbar") {
      return (
        <div className="alertSnackbar__snackbar">
          {showAlert && (
            <Alert severity={color} onClose={UserAction}>
              {message}
            </Alert>
          )}
        </div>
      );
    }

    return (
      <div className="alertSnackbar__alert">
        {showAlert && (
          <Alert severity={color} onClose={UserAction}>
            {message}
          </Alert>
        )}
      </div>
    );
  };

  return RenderComponent(type);
};
AlertSnackbarComponent.propTypes = {
  showAlert: PropTypes.bool,
  type: PropTypes.string,
  message: PropTypes.string,
  color: PropTypes.string,
  timer: PropTypes.number,
  UserAction: PropTypes.func,
};

export default AlertSnackbarComponent;
