/**
 * @file
 * Contains Sign Up component.
 *
 * Login contains next attributes:
 *  # title {string} - Rewrite default title.
 *  # companyLogo {node} - Set up custom logo.
 *  # createAccountHandler {function} - handle 'Create account click'.
 *  # loginLinkHandler {function} - handle 'Login link click'.
 *  # styles {object} - Rewrite default styles. Contain keys - logoStyle, titleStyle, formStyle, buttonStyle.
 *  # errorsApi {object} - Error handling. Contain of field key and error message.
 */

import React, { useEffect, useState } from "react";
import { TextField, Button, Grid } from "@material-ui/core";
import "./signUp.css";
import FeUtils from "@fe-sales/common-utils";
import DefaultLogo from "./../assets/img/logo512.png";
import PropTypes from "prop-types";

const SignUpComponent = (props) => {
  const {
    title,
    companyLogo,
    styles,
    errorsApi = {},
    loginLinkHandler,
    createAccountHandler,
  } = props;

  const formFields = {
    address: "",
    address2: "",
    city: "",
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    passwordReType: "",
    phone: "",
    state: "",
    userName: "",
    zip: "",
  };

  /**
   * Values state.
   */
  const [values, setValues] = useState(formFields);

  /**
   * User error state.
   */
  const [errors, setErrors] = useState({});

  /**
   * Show API Errors
   */
  useEffect(() => {
    if (Object.keys(errorsApi).length) {
      setErrors(errorsApi);
    }
  }, [errorsApi]);

  /**
   * Password match handler.
   */
  const passwordMatch = () => values.password === values.passwordReType;

  /**
   * SignUp submit handler.
   */
  const onSubmitHandler = (e) => {
    e.preventDefault();
    const emptyInputs = Object.keys(values).filter((key) => values[key] === "");

    if (emptyInputs.length) {
      setErrors(
        emptyInputs.reduce((o, key) => ({ ...o, [key]: "Required field" }), {})
      );
      return false;
    }

    if (values.email && !FeUtils.validateEmail(values.email)) {
      setErrors({ email: "Must be a valid Email" });
      return false;
    }

    if (values.password && !FeUtils.validatePassword(values.password)) {
      setErrors({ password: "Min 8 characters, capital and number" });
      return false;
    }

    if (!passwordMatch()) {
      setErrors({ passwordReType: "Passwords don't match" });
      return false;
    }

    createAccountHandler(values);
  };

  /**
   * Input change handler.
   * @param e
   * Event object.
   */
  const inputChangeHandler = (e) => {
    const inputId = e.target.id;
    setValues({ ...values, [inputId]: e.target.value });
    setErrors({ ...errors, [inputId]: "" });
  };

  return (
    <div className="cui_sign-up_container">
      <div className="cui_sign-up_left-side">
        <img
          src={companyLogo ? companyLogo : DefaultLogo}
          alt=""
          className="cui_sign-up_logo"
          style={styles?.logoStyle}
        />
        <div className="cui_sign-up_title" style={styles?.titleStyle}>
          {title ? title : "Welcome"}
        </div>
      </div>
      <div className="cui_sign-up_right-side" style={styles?.formStyle}>
        <form className="cui_sign-up_form" onSubmit={onSubmitHandler}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="firstName"
                label="First Name"
                onChange={inputChangeHandler}
                error={!!errors?.firstName}
                helperText={!!errors?.firstName ? errors?.firstName : ""}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="lastName"
                label="lastName"
                onChange={inputChangeHandler}
                error={!!errors?.lastName}
                helperText={!!errors?.lastName ? errors?.lastName : ""}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="email"
                label="Email"
                // type='email'
                onChange={inputChangeHandler}
                error={!!errors?.email}
                helperText={!!errors?.email ? errors?.email : ""}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="userName"
                label="Username"
                onChange={inputChangeHandler}
                error={!!errors?.userName}
                helperText={!!errors?.userName ? errors?.userName : ""}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="password"
                label="Password"
                type="password"
                onChange={inputChangeHandler}
                error={!!errors?.password}
                helperText={!!errors?.password ? errors?.password : ""}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="passwordReType"
                label="Re-type Password"
                type="password"
                onChange={inputChangeHandler}
                error={!!errors?.passwordReType}
                helperText={
                  !!errors?.passwordReType ? errors?.passwordReType : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="address"
                label="Address"
                onChange={inputChangeHandler}
                error={!!errors?.address}
                helperText={!!errors?.address ? errors?.address : ""}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="address2"
                label="Address 2"
                onChange={inputChangeHandler}
                error={!!errors?.address2}
                helperText={!!errors?.address2 ? errors?.address2 : ""}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="city"
                label="City"
                onChange={inputChangeHandler}
                error={!!errors?.city}
                helperText={!!errors?.city ? errors?.city : ""}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="state"
                label="State"
                onChange={inputChangeHandler}
                error={!!errors?.state}
                helperText={!!errors?.state ? errors?.state : ""}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="zip"
                label="Zip"
                onChange={inputChangeHandler}
                error={!!errors?.zip}
                helperText={!!errors?.zip ? errors?.zip : ""}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="phone"
                label="Phone"
                onChange={inputChangeHandler}
                error={!!errors?.phone}
                helperText={!!errors?.phone ? errors?.phone : ""}
              />
            </Grid>
          </Grid>
          <div className="cui_sign-up_button-wrap">
            <Button
              variant="contained"
              type="submit"
              style={styles?.buttonStyle}
            >
              Sign Up
            </Button>
            {loginLinkHandler && (
              <div className="cui_sign-up_login">
                Already have an account ?
                <Button onClick={loginLinkHandler}>Login</Button>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};
SignUpComponent.propTypes = {
  title: PropTypes.string,
  authType: PropTypes.string,
  companyLogo: PropTypes.node,
  styles: PropTypes.object,
  errorsApi: PropTypes.object,
  createAccountHandler: PropTypes.func,
  loginLinkHandler: PropTypes.func,
};

export default SignUpComponent;
