import { createSlice } from "@reduxjs/toolkit";

export const userSingleSlice = createSlice({
  name: "userSingleSlice",
  initialState: {},
  reducers: {
    addUser: (state, action) => {
      return action.payload;
    },
    updateUser: (state, data) => {
      state.data = data.payload;
    },
  },
});

export const userSingleState = (state) => state.userSingleSlice;

export const { addUser, updateUser } = userSingleSlice.actions;

export default userSingleSlice.reducer;
