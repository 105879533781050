/**
 * @file
 * Contains Profile service.
 */

import { apiClient } from "./apiClients.service";
import {
  PROFILE_ENDPOINT,
  RESET_PASSWORD_ENDPOINT,
  PROFILE_ADDRESS_ENDPOINT,
  UPDATE_AVATAR_ENDPOINT,
  USERS_SIP,
  WORKCENTER_LINK,
  ANALYTICS_LINK,
} from "../constants/apiEnpoints.constant";
import Bridge from '../bridge';
import urls from '../routes/urls';
import FeUtils from '@fe-sales/common-utils/fe-utils';

/**
 * Get profile.
 */
const getProfile = async () => {
  const response = await apiClient.get(PROFILE_ENDPOINT);
  // const roles = FeUtils.rolesRule(response.data?.roles);
  // if (roles?.length && roles?.includes('workcenter')) {
  //   window.location.href = WORKCENTER_LINK;
  //   return { redirect: true };
  // } else if (roles?.length && !roles?.includes('sales') && roles?.includes('analytics')) {
  //   window.location.href = ANALYTICS_LINK;
  //   return { redirect: true };
  // }
  return response.data;
};

/**
 * Get profile avatar.
 */
const getProfileAvatar = async () => {
  const response = await apiClient.get(UPDATE_AVATAR_ENDPOINT, {
    responseType: "arraybuffer",
  });
  return response.data;
};

/**
 * Update Profile details.
 * @param {object} field.
 * Updated field.
 */
const updateProfile = async (field) => {
  const { firstName, lastName, email, mobile } = field;
  try {
    await apiClient.put(PROFILE_ENDPOINT, {
      firstName,
      lastName,
      email,
      mobile,
    });
  } catch (e) {
    console.error(e);
  }
};

/**
 * Update Profile Address details.
 * @param {object} fields.
 * Updated fields.
 */
const updateProfileAddress = async (fields) => {
  const { address } = fields;
  try {
    !!Object.keys(address || {})?.length && await apiClient.put(PROFILE_ADDRESS_ENDPOINT, {
      ...address,
      location: {
        coordinates: [0, 0],
      },
    });
  } catch (e) {
    console.error(e);
  }
};

/**
 * Update Password.
 * @param {string} password.
 * Updated password.
 */
const updatePassword = async (password) => {
  try {
    await apiClient.put(RESET_PASSWORD_ENDPOINT, { password });
  } catch (e) {
    console.error(e);
  }
};

/**
 * Update Avatar.
 * @param {string} image.
 * Updated Avatar.
 */
const updateAvatar = async (image) => {
  try {
    await apiClient.post(UPDATE_AVATAR_ENDPOINT, image, {
      headers: {
        "Content-Type": `multipart/form-data; boundary=${image._boundary}`,
        Accept: null,
      },
    });
  } catch (e) {
    console.error(e);
  }
};

/**
 * Get profile sip data.
 * @param {string} id.
 * User ID.
 */
const getSipDataForProfile = async (id) => {
  const response = await apiClient.get(`${USERS_SIP}/${id}`);
  return response.data;
};

export default {
  getProfile,
  updateProfile,
  updatePassword,
  updateProfileAddress,
  updateAvatar,
  getProfileAvatar,
  getSipDataForProfile,
};
