import { createSlice } from "@reduxjs/toolkit";

export const leadsSlice = createSlice({
  name: "leadsSlice",
  initialState: {
    leads: [],
    status: [],
    users: [],
    myLeads: [],
    today: [],
  },
  reducers: {
    addLeads: (state, data) => {
      state.leads = data.payload;
    },
    addLeadsMyLeads: (state, data) => {
      state.myLeads = data.payload;
    },
    addLeadsToday: (state, data) => {
      state.today = data.payload;
    },
    updateLead: (state, action) => {
      state.leads = state.leads.map((item) => {
        if (item?.id === action.payload?.id) {
          return { ...item, [action.payload.key]: action.payload.value };
        } else {
          return item;
        }
      })
    },
    addStatus: (state, action) => {
      state.status = action.payload;
    },
    addUsers: (state, action) => {
      state.users = action.payload;
    },
  },
});

export const leadsState = (state) => state.leadsSlice;

export const { addLeads, addLeadsMyLeads, addLeadsToday, addStatus, addUsers, updateLead } = leadsSlice.actions;

export default leadsSlice.reducer;
