import { createSlice } from "@reduxjs/toolkit";

export const accountsSlice = createSlice({
  name: "accountsSlice",
  initialState: {
    accounts: [],
  },
  reducers: {
    addAccounts: (state, data) => {
      state.accounts = data.payload;
    },
    updateAccount: (state, action) => {
      const indexId = state.accounts.findIndex(
        (obj) => obj.id === action.payload.id
      );
      state.accounts[indexId][action.payload.key] = action.payload.value;
    },
  },
});

export const accountsState = (state) => state.accountsSlice.accounts;

export const { addAccounts, updateAccount } = accountsSlice.actions;

export default accountsSlice.reducer;
