import React from "react";
import { Grid } from "@material-ui/core";
import "./signUpUser.scss";
import { SignUpUserPage } from "@fe-sales/common-ui";
import logo from "./../../assets/img/logo.svg";
import urls from "../../routes/urls";
import AuthService from "../../services/auth.service";
import { LOGIN_ENDPOINT } from "../../constants/apiEnpoints.constant";

const SignUpUserComponent = (props) => {
  const { history } = props;

  const updatePasswordHandler = async (userCredentials) => {
    const { user, code, password, repassword } = userCredentials;

    /*try {
            await AuthService.loginApi(LOGIN_ENDPOINT, {email: user, password});
            history.push({
                pathname: urls.dashboard,
            })
        } catch (e) {
            console.error(e);
        }*/
  };

  const loginHandler = () => {
    history.push(urls.public.login);
  };

  return (
    <div className="containerAuth">
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid
          item
          xs={10}
          sm={10}
          md={6}
          lg={4}
          className="signup-user-container"
        >
          <SignUpUserPage
            forgotPasswordHandler={() => console.log(111)}
            title={"Sales & Marketing"}
            companyLogo={logo}
            styles={{
              logoStyle: { width: "100px", height: "100px" },
              titleStyle: {
                color: "#FFF",
                fontSize: "1.6rem",
                padding: "10px",
              },
              dialogStyle: { backgroundColor: "rgba(255, 255, 255, 0.2)" },
            }}
            onSignUpUser={updatePasswordHandler}
            onLoginHandler={loginHandler}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default SignUpUserComponent;
