/**
 * @file
 * Contains constants of API endpoints.
 */
export const API_URL = process.env.REACT_APP_API_URL || "https://support.feeagle.com/api/";
export const MAIN_API_URL = process.env.REACT_APP_MAIN_API_URL || "https://support.feeagle.com/api-analytics/";
export const WORKCENTER_LINK = process.env.REACT_APP_WORKCENTER_LINK || '';
export const SALES_LINK = process.env.REACT_APP_SALES_LINK || '';
export const LOCAL_LOGIN = process.env.REACT_APP_LOGIN === 'true' || false;

export const LOGIN_ENDPOINT = "/login";
export const REFRESH_TOKEN = "/refresh-token";

export const WORKORDER_LIST_WIDGET = "job/list";
export const CUSTOMER_OVERVIEW_WIDGET = "company/account/stats";
export const CUSTOMER_OVERVIEW_WIDGET_TOTAL = "company/account/stats/totals";
export const WORKORDER_CHART_WIDGET = "job/active/closed/count/";
export const WORKORDER_RADAR_MAP = "job/radar/geo/data/";
export const SIGNUP_COUNT_DAILY = "company/daily/signup/count/";
export const SIGNUP_COUNT_MONTHLY = "company/monthly/signup/count/";
export const FUND_PAYOUT_RADAR = "worldwide/payout";
export const FUND_PAYOUT_TOTALS_RADAR = "worldwide/payout/totals";
export const FUND_PAYOUT_TOTALS_STATES_RADAR = "us/payout/totals";
export const FUND_PAYOUT_STATES_RADAR = "us/payout";

export const PROFILE_ENDPOINT = "/profile";
export const RESET_PASSWORD_ENDPOINT = "/reset/password";

export const PROFILE_ADDRESS_ENDPOINT = `${PROFILE_ENDPOINT}/address`;
export const UPDATE_AVATAR_ENDPOINT = `${PROFILE_ENDPOINT}/avatar`;

export const USERS_SIP = "/sip/users";
export const AVAILABLE_PHONE = "/available/phone";
export const SIP_DOMAINS = "/sip/domains";
export const EMAIL_SIP = "/voice/email";
export const PASSWORD_SIP = "/sip/password";
export const RELEASE_PHONE = "/release/phone";
export const ASSIGN_PHONE = "/assign/phone";

