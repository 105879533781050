import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import LoginComponent from "./pages/login/login";
import ForgotPasswordComponent from "./pages/forgotPassword/forgotPassword";
import SignUpUserComponent from "./pages/signUpUser/signUpUser";
import SignUpComponent from "./pages/signUp/signUp";
import HomeComponent from "./pages/home/home";
import LeadsComponent from "./pages/leads/leads";
import CampaignComponent from "./pages/campaign/campaign";
import AccountsComponent from "./pages/accounts/accounts";
import UsersComponent from "./pages/users/users";
import urls from "./routes/urls";
import { createBrowserHistory } from "history";
import RootRedirect from "./routes/RootRedirect";
import PublicRoute from "./routes/PublicRoute";
import PrivateRoute from "./routes/PrivateRoute";
import Logout from "./pages/logout/Logout";
import CallLogs from "./pages/callLogs/CallLogs";
import PhoneListScreen from './pages/phoneList/phoneList';
import CallRecordings from './pages/callRecordings/CallRecordings';

export const history = createBrowserHistory();

function App() {

  return (
    <Router history={history} basename="/sales">
      <Suspense fallback="...loading">
        <Switch>
          <RootRedirect exact path={urls.root} />

          <PublicRoute
            exact
            path={urls.public.login}
            component={LoginComponent}
          />
          <PublicRoute
            exact
            path={urls.public.forgotPassword}
            component={ForgotPasswordComponent}
          />
          <PublicRoute
            exact
            path={urls.public.signUpUser}
            component={SignUpUserComponent}
          />
          <PublicRoute
            exact
            path={urls.public.signUp}
            component={SignUpComponent}
          />

          <PrivateRoute exact path={urls.dashboard} component={HomeComponent} />
          <PrivateRoute exact path={urls.leads} component={LeadsComponent} />
          <PrivateRoute
            exact
            path={urls.campaign}
            component={CampaignComponent}
          />
          <PrivateRoute
            exact
            path={urls.accounts}
            component={AccountsComponent}
          />
          <PrivateRoute exact path={urls.users} component={UsersComponent} />
          <PrivateRoute exact path={urls.logout} component={Logout} />
          <PrivateRoute exact path={urls.callLogs} component={CallLogs} />
          <PrivateRoute exact path={urls.phoneList} component={PhoneListScreen} />
          <PrivateRoute exact path={urls.callRecordings} component={CallRecordings} />

          <Redirect to={urls.root} />
        </Switch>
      </Suspense>
    </Router>
  );
}

export default App;
