/**
 * @file
 * Contains Contact service.
 */

import { apiClient } from './apiClients.service';
import { CONTACTS_ENDPOINT, CONTACTS_LIST_ENDPOINT } from '../constants/apiEnpoints.constant';

/**
 * Add contact.
 * Lead ID
 * @param {object} params.
 * Updated field.
 */
const addContact = async (params) => {
  try {
    const response = await apiClient.post(`${CONTACTS_ENDPOINT}`, params);
    return response.data;
  } catch (e) {
    console.error(e);
  }
};

/**
 * Update contact.
 * @param {object} params.
 * Updated field.
 */
const updateContact = async (params) => {
  try {
    await apiClient.put(`${CONTACTS_ENDPOINT}`, params);
  } catch (e) {
    console.error(e);
  }
};

const getContactList = async (id) => {
  try {
    const response = await apiClient.get(`${CONTACTS_LIST_ENDPOINT}/${id}`);
    return response.data;
  } catch (e) {
    console.error(e);
  }
};

/**
 * Delete contact
 * @param {string} id.
 * Contact Id.
 */
const deleteContact = async (id) => {
  try {
    await apiClient.delete(`${CONTACTS_ENDPOINT}/${id}`);
  } catch (e) {
    console.error(e);
  }
};

export default {
  addContact,
  getContactList,
  updateContact,
  deleteContact,
};
