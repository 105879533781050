import React, {useEffect, useState} from "react";
import { NavLink } from "react-router-dom";
import "./left-navigation.scss";
import links from "../../constants/linkObject";
import links_bottom from "../../constants/linkObject";
import { useDispatch, useSelector } from "react-redux";
import { profileStateData, toggleProfile } from "../../slices/profile-slice";
import useWindowSize from "../../hooks/useWindowSize";
import FeUtils from "@fe-sales/common-utils/fe-utils";
import link from '../../assets/img/icons8-external-link.png';

const InstallPWA = () => {
  let deferredPrompt;

  const [installable, setInstallable] = useState(false);

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
      // Update UI notify the user they can install the PWA
      setInstallable(true);
    });

    window.addEventListener('appinstalled', () => {
      // Log install to analytics
      console.log('INSTALL: Success');
    });
  }, []);

  const handleInstallClick = (e) => {
    // Hide the app provided install promotion
    setInstallable(false);
    // Show the install prompt
    deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the install prompt');
      } else {
        console.log('User dismissed the install prompt');
      }
    });
  };

  if (!installable) {
    return null;
  }

  return (
    <button id="setup_button" className="leftNav__link" onClick={handleInstallClick}>
      <i className="icons8-download"/>
      <span>Install</span>
    </button>
  );
};

const LeftNavigation = () => {
  const dispatch = useDispatch();
  const profileData = useSelector(profileStateData);
  const [menu, toggleMenu] = useState(localStorage.getItem('mobileMenu') === 'true');
  const [width,] = useWindowSize();
  const [crmAdmin, setCrmAdmin] = useState(false);

  useEffect(() => {
    const mobileMenu = localStorage.getItem('mobileMenu');
    if (mobileMenu == null) {
      //if user not toggle menu, then check window size
      width > 667 ? toggleMenu(false) : toggleMenu(true);
    }
  }, [menu]);

  useEffect(() => {
    if (profileData?.roles) {
      profileData?.roles?.includes('crm-admin') && setCrmAdmin(true);
    }
  }, [profileData])

  const profileClickHandler = () => {
    dispatch(toggleProfile(true));
  };

  const onToggleMenu = () => {
    localStorage.setItem('mobileMenu', `${!menu}`);
    toggleMenu(!menu);
  };

  return (
    <aside className={`leftNav ${menu ? "mobile" : ""}`}>
      <div>
        <div onClick={() => onToggleMenu()}
             className="leftNav__back"
        >
          <i className="icons8-back" />
          <i className="icons8-menu" />
        </div>
        <nav className="leftNav__nav">
          {links &&
            Object.keys(links).map((item, i) => {
              if (links_bottom[item].layout === "bottom" || (links_bottom[item]?.admin && !crmAdmin)) {
                return;
              }
              return (
                <NavLink
                  to={links[item].url}
                  activeClassName="leftNav__linkActive"
                  key={i}
                  className="leftNav__link"
                >
                  <i className={links[item].icon} />
                  <span>{links[item].label}</span>
                </NavLink>
              );
            })}
        </nav>
      </div>

      <nav className="leftNav__nav leftNav__bottom">
        <InstallPWA />
        {links_bottom && (
          Object.keys(links_bottom).map((item, i) => {
            if (links_bottom[item].layout !== "bottom") {
              return;
            }
            if (links_bottom[item].type !== 'out' || (links_bottom[item].type === 'out' && FeUtils.rolesRule(profileData?.roles)?.includes(item))) {
              return links_bottom[item].label === links_bottom.profile.label ? (
                <div
                  key={i}
                  onClick={profileClickHandler}
                  className="leftNav__link"
                >
                  <i className={links_bottom[item].icon} />
                  <span>{links_bottom[item].label}</span>
                </div>
              ) : (
                links_bottom[item].label === links_bottom.workcenter.label  || links_bottom[item].label === links_bottom.analytics.label ?
                <a
                    href={links_bottom[item]?.url}
                    target='_blank'
                    key={i}
                    className="leftNav__link"
                >
                  {item !== 'workcenter' && item !== 'analytics' ?
                  <i className={(!!links_bottom[item]?.iconMini && menu) ? links_bottom[item]?.iconMini : links_bottom[item].icon} />
                  : <img src={link} alt={links_bottom[item]?.label} />}
                  {(!!links_bottom[item]?.iconLetter && menu) && <b>{links_bottom[item]?.iconLetter}</b>}
                  <span>{links_bottom[item].label}</span>
                </a>
                :
                <NavLink
                  to={links_bottom[item].url}
                  activeClassName="leftNav__linkActive"
                  key={i}
                  className="leftNav__link"
                >
                  <i className={(!!links_bottom[item]?.iconMini && menu) ? links_bottom[item]?.iconMini : links_bottom[item].icon} />
                  {(!!links_bottom[item]?.iconLetter && menu) && <b>{links_bottom[item]?.iconLetter}</b>}
                  <span>{links_bottom[item].label}</span>
                </NavLink>
            )}
          })
        )}
      </nav>
    </aside>
  );
};

export default LeftNavigation;
