import React, { useEffect, useState } from "react";
import "./campaign-track-view.scss";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import feUtils from "@fe-sales/common-utils";
import CampaignService from '../../services/campaign.service';

const CampaignTrackView = (props) => {
  const {
    campaign,
  } = props;

  const [filter, setFilter] = useState('general');

  const [, setDataLoaded] = useState(false);

  const [campaignId, setCampaignId] = useState('');

  const [tracks, setTracks] = useState([]);

  useEffect(() => {
    const handleTracks = async () => {
      try {
        const response = await CampaignService.getCampaignTrackList(
          filter,
          campaignId
        );
        setTracks(response);
      } catch (e) {
        console.error(e);
      } finally {
        setDataLoaded(true);
      }
    };

    if (campaignId) {
      handleTracks();
    }
  }, [filter, campaignId]);

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleCampaignSelectChange = (event) => {
    setCampaignId(event.target.value);
    // setCampaignId('SD_WAN');
    setFilter("general");
  };

  const renderDomainAliases = (items) =>
    items.map((item, i) => {
      return (
        <div key={i} className="subTitle__sub">
          &nbsp;&nbsp;{item}
        </div>
      );
    });

  const renderContacts = (items) =>
    items.map((contact, i) => {
      return (
        <div className="subTitle__sub" key={i}>
          &nbsp;&nbsp;
          <a href={`mailto:${contact.email}`}>{contact.name.fullName}</a>
          {contact.role && <div>{contact.role}</div>}
          <br />
        </div>
      );
    });

  const renderTracks = (items) =>
    items?.map((track, i) => {
      const trackInfo = track?.companyInfo;
      return (
        <div className="row traffic-row" key={i}>
          <div className="col-4">
            <div>
              <div className="title">
                <a href={`mailto:${track.email}`}>{track.email}</a>
              </div>
              {track.ip && <div className="title">IP: {track.ip}</div>}
              <div className="subTitle">
                {feUtils.formatDateTime(track?.indexedAt)}
              </div>
              <div className="subTitle">
                <a>Visitor Tracks</a>
              </div>
              <div className="subTitle">
                {trackInfo && trackInfo?.details?.logo && (
                  <img src={trackInfo?.details.logo} alt='logo' />
                )}
              </div>
            </div>
          </div>
          <div className="col-4">
            <div>
              <div className="title">
                {(trackInfo &&
                  trackInfo?.details?.legalName) ||
                  "--"}
              </div>
              <div className="subTitle">
                {(trackInfo &&
                  trackInfo?.details?.description) ||
                  "--"}
              </div>
            </div>
          </div>
          <div className="col-2">
            <div className="subTitle">Contacts:</div>
            {(trackInfo && trackInfo?.contacts) &&
              renderContacts(trackInfo?.contacts)}
          </div>
          <div className="col-2">
            <div className="subTitle">Domains:</div>
            {trackInfo && trackInfo?.details?.domain && (
              <div className="subTitle__sub">
                &nbsp;&nbsp;{trackInfo.details.domain}
              </div>
            )}
            {trackInfo &&
              trackInfo?.details?.domainAliases &&
              renderDomainAliases(trackInfo.details.domainAliases)}
            <div className="subTitle">Sector:</div>
            {trackInfo && trackInfo?.details?.category && (
              <div className="subTitle__sub">
                &nbsp;&nbsp;
                {trackInfo.details.category?.sector || "--"}
              </div>
            )}
            <div className="subTitle">Industry Group:</div>
            {trackInfo && trackInfo?.details?.category && (
              <div className="subTitle__sub">
                &nbsp;&nbsp;
                {trackInfo.details.category?.industryGroup || "--"}
              </div>
            )}
            <div className="subTitle">Industry:</div>
            {trackInfo && trackInfo?.details?.category && (
              <div className="subTitle__sub">
                &nbsp;&nbsp;
                {trackInfo.details.category?.industry || "--"}
              </div>
            )}
          </div>
        </div>
      );
    });

  return (
    <div className="campaignTrackView">
      <div className="container">
        <header>
          <p>Check the status of the campaign and tracks for the users.</p>

          <div className="campaignTrackView__filters">
            <FormControl>
              <InputLabel id="demo-simple-select-label">
                Select available campaigns
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={campaignId}
                onChange={handleCampaignSelectChange}
              >
                {campaign?.map((item, i) => (
                  <MenuItem value={item.id} key={i}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="traffic"
                name="gender1"
                value={filter}
                onChange={handleFilterChange}
              >
                <FormControlLabel
                  value="all"
                  control={<Radio />}
                  label="All Traffic"
                />
                <FormControlLabel
                  value="general"
                  control={<Radio />}
                  label="Generic Traffic"
                />
                <FormControlLabel
                  value="known"
                  control={<Radio />}
                  label="Identified Traffic"
                />
              </RadioGroup>
            </FormControl>
          </div>
        </header>

        {!!tracks?.length && (
          <div className="campaignTrackView__report">
            {renderTracks(tracks)}
          </div>
        )}
      </div>
    </div>
  );
};

export default CampaignTrackView;
