import React, {useEffect, useState} from "react";
import "./home.scss";
import PageLayout from "../../components/page-layout/page-layout";
import { NavLink } from "react-router-dom";

import {
    Table,
    CircularProgress,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';
import urls from '../../routes/urls';
import { useDispatch, useSelector } from 'react-redux';
import { addLeadsMyLeads, addLeadsToday, leadsState } from '../leads/leads-slice';
import LeadsService from '../../services/leads.service';

const HomeComponent = (props) => {
    const [dataLoaded, setDataLoaded] = useState(false);

    const leadsStateData = useSelector(leadsState);

    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            try {
                setDataLoaded(false);
                const responseMyLeads = await LeadsService.getLeads({
                    category: 'myleads',
                    limit: 10,
                });
                const responseToday = await LeadsService.getLeads({
                    category: 'today',
                    limit: 10,
                });
                dispatch(addLeadsMyLeads(responseMyLeads));
                dispatch(addLeadsToday(responseToday));
            } catch (e) {
                console.error(e);
            } finally {
                setDataLoaded(true);
            }
        };

        fetchData();
    }, [dispatch]);

    return (
        <PageLayout>
            <div className="container-home">

                <div className="widget">
                    {dataLoaded ? (
                      <>
                        <header>
                            <h3>My Leads</h3>
                            <NavLink to={{ pathname:urls.leads, tab:'myleads' }}>
                                <i className="icons8-more-than"/>
                            </NavLink>
                        </header>
                        <TableContainer>
                            <Table aria-label="simple table" size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Lead Name</TableCell>
                                        <TableCell>Company</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell>Lead Source</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {leadsStateData?.myLeads?.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row">
                                                {row?.name}
                                            </TableCell>
                                            <TableCell>{row?.companyName}</TableCell>
                                            <TableCell>{row?.email}</TableCell>
                                            <TableCell>{row?.source}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                      </>
                    ) : (
                      <CircularProgress />
                    )}
                </div>

                <div className="widget">
                    {dataLoaded ? (
                      <>
                        <header>
                            <h3>Todays</h3>
                            <NavLink to={{ pathname:urls.leads, tab:'today' }}>
                                <i className="icons8-more-than"/>
                            </NavLink>
                        </header>
                        <TableContainer >
                            <Table aria-label="simple table" size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Lead Name</TableCell>
                                        <TableCell>Company</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell>Lead Source</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {leadsStateData?.today?.map((row, i) => (
                                        <TableRow key={i}>
                                            <TableCell component="th" scope="row">
                                                {row?.name}
                                            </TableCell>
                                            <TableCell>{row?.companyName}</TableCell>
                                            <TableCell>{row?.email}</TableCell>
                                            <TableCell>{row?.source}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                      </>
                    ) : (
                      <CircularProgress />
                    )}
                </div>

            </div>
        </PageLayout>
    );
}

export default HomeComponent;
