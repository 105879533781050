/**
 * @file
 * Contains Call Recordings component.
 */

import React, { useEffect, useState } from "react";
import BackdropElement from "../../components/BackdropElement/BackdropElement";
import "./CallRecordings.scss";
import { useDispatch, useSelector } from "react-redux";
import PageLayout from "../../components/page-layout/page-layout";
import TableElement from "../../components/TableElement/TableElement";
import EmptyListElement from "../../components/EmptyListElement/EmptyListElement";
import CallLogsService from '../../services/callLogs.service';
import { addCallRecordings, callRecordingsState } from './call-recordings-slice';

const CallRecordings = (props) => {
  const tableCells = {
    dateCreated: {
      name: "Date",
      extra: "dates",
      format: "DD MMM YYYY, hh:mm a",
    },
    status: {name: "Status"},
    recording: {name: "Recording"},
    duration: {name: "Duration", extra: "time"},
    callDetails: {name: "Call Details"},
  };

  const pageSize = 20;

  const dispatch = useDispatch();

  const tableData = useSelector(callRecordingsState);

  const [dataLoaded, setDataLoaded] = useState(false);
  const [paginationState, setPaginationState] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const requestParams = {
        pageNumber: 0,
        pageSize,
      };

      try {
        const response = await CallLogsService.getCallRecordings(requestParams);
        dispatch(addCallRecordings(response?.list));
        setPaginationState(response?.page);
      } catch (e) {
        console.error(e);
      } finally {
        setDataLoaded(true);
      }
    };

    fetchData();
  }, [dispatch]);

  const paginationClickHandler = async (key) => {
    const requestParams = {
      pageNumber: (key === 'next') ? paginationState?.nextPage?.pageNumber : paginationState?.previousPage?.pageNumber,
      pageToken: (key === 'next') ? paginationState?.nextPage?.pageToken : paginationState?.previousPage?.pageToken,
      pageSize,
    };
    try {
      const response = await CallLogsService.getCallRecordings(requestParams);
      dispatch(addCallRecordings(response?.list));
      setPaginationState(response?.page);
    } catch (e) {
      console.error(e);
    } finally {
      setDataLoaded(true);
    }
  };

  return (
    <PageLayout>
      <div className="top-calls__table-wrap">
        {dataLoaded ? (
          Object.keys(tableData).length ? (
            <>
              <TableElement
                tableData={tableData}
                // tableRowClickHandler={tableRowClickHandler}
                tableCells={tableCells}
              />
              <div className="top-calls__pagination">
                <i
                  className={`icons8-chevron-left ${!paginationState?.previousPage && 'disabled'}`}
                  onClick={() => paginationClickHandler('prev')}
                />
                <i
                  className={`icons8-chevron-right ${!paginationState?.nextPage && 'disabled'}`}
                  onClick={() => paginationClickHandler('next')}
                />
              </div>
            </>
          ) : (
            <EmptyListElement text="Call logs are empty" />
          )
        ) : (
          <BackdropElement />
        )}
      </div>

    </PageLayout>
  )
};

export default CallRecordings;
