/**
 * @file
 * Contains Roles service.
 */
import { apiClient } from "./apiClients.service";
import { ROLES_ENDPOINT } from "../constants/apiEnpoints.constant";

/**
 * Get Leads users.
 */
const getRoles = async () => {
  try {
    const response = await apiClient.get(ROLES_ENDPOINT);
    return response.data;
  } catch (e) {
    console.error(e);
  }
};

export default {
  getRoles,
};
