import React, { useEffect, useState } from "react";
import "./ContactFormComponent.scss";
import { Button, TextField } from '@material-ui/core';
import FeUtils from '@fe-sales/common-utils/fe-utils';
import ContactsService from '../../services/contacts.service';

const ContactFormComponent = (props) => {
  const { id } = props;

  const [contactsList, setContactsList] = useState([]);
  const [contactValue, setContactValue] = useState({});
  const [errorContact, setErrorContact] = useState({});
  const [contactForm, setContactForm] = useState(false);
  const [updateForm, setUpdateForm] = useState(false);
  const [updateComponent, setUpdateComponent] = useState(false);
  const [submitButtonNotDisabled, setSubmitButtonNotDisabled] = useState(false);

  useEffect(() => {
    onGetContactList();
  }, [id, updateComponent])

  useEffect(() => {
    const mandatoryFields = ["title", "phone", "email", "lastName", "firstName"];
    const filledItems = mandatoryFields.filter((item) => !(item in contactValue) || !contactValue?.[item]);
    setSubmitButtonNotDisabled(!filledItems?.length && !Object.keys(errorContact)?.length)
  }, [errorContact, contactValue])

  const onGetContactList = async () => {
    try {
      const contactsList = await ContactsService.getContactList(id);
      setContactsList(contactsList);
    } catch (e) {
      console.error(e);
    }
  }

  const addContactBtn = () => {
    contactForm && setContactValue({});
    setContactForm(!contactForm);
  };

  const handleChange = (event) => {
    let value = event.target.value;
    if (event.target.id === "email") {
      if (!FeUtils.validateEmail(value)) {
        setErrorContact(prevState => ({ ...prevState, email: "Enter valid email" }));
      } else {
        const { email , ...rest } = errorContact;
        setErrorContact(rest);
      }
    }

    if (event.target.id === "phone") {
      if (event.target.value[0] !== "+") {
        value = `+${value}`;
      }
      if (!FeUtils.phoneNumberValidation(value)) {
        setErrorContact(prevState => ({ ...prevState, phone: "Enter valid mobile" }));
      } else {
        const { phone , ...rest } = errorContact;
        setErrorContact(rest);
      }
    }

    setContactValue({ ...contactValue, [event.target.id]: value });
  };

  const addContactHandler = async (e) => {
    e.preventDefault();
    const defaultValues = {
      companyName: '',
      role: '',
      subRole: '',
      seniority: '',
    }
    const sendContactValues = { ...contactValue, ...defaultValues, leadId: id };

    try {
      if (updateForm) {
        await ContactsService.updateContact(sendContactValues);
        setUpdateForm(false);
        setUpdateComponent(!updateComponent);
      } else {
        const res = await ContactsService.addContact(sendContactValues);
        // setContactsList([...contactsList, res]);
        onGetContactList();
      }
      setContactForm(false);
      setContactValue({});
    } catch (e) {
      console.error(e);
      setContactForm(false);
    }
  };

  const editAccountHandler = (id) => {
    contactsList.forEach(item => {
      if (item.id === id) {
        setContactValue(item);
        setContactForm(true);
        setUpdateForm(true);
      }
    })
  }

  const deleteAccountHandler = async (id) => {
    try {
      await ContactsService.deleteContact(id);
      setUpdateComponent(!updateComponent);
    } catch (e) {
      console.error(e);
    }
  }

  const cancelUpdateHandler = () => {
    setUpdateForm(false);
    setContactValue({});
    setContactForm(false);
  };

  return (
    <div className="contactComponent">
      <header>
        <div>Contacts</div>
        <div>
          <button onClick={addContactBtn}>+ Add Contact</button>
        </div>
      </header>

      <div className="contactComponent__plane-inside">
        {contactForm && (
          <form
            className="contactComponent__form"
            onSubmit={addContactHandler}
          >
            <div className="contactComponent__rowForm">
              <TextField
                required
                id="firstName"
                label={"First Name"}
                value={contactValue?.firstName}
                onChange={handleChange}
              />
              <TextField
                required
                id="lastName"
                label={"Last Name"}
                value={contactValue?.lastName}
                onChange={handleChange}
              />
            </div>
            <div className="contactComponent__rowForm">
              <TextField
                required
                id="email"
                label={"Email"}
                value={contactValue?.email}
                onChange={handleChange}
                error={!!errorContact?.["email"]}
                helperText={
                  !!errorContact?.["email"] ? errorContact?.["email"] : ""
                }
              />
              <TextField
                required
                id="phone"
                label={"Mobile"}
                value={contactValue?.phone}
                onChange={handleChange}
                error={!!errorContact?.["phone"]}
                helperText={
                  !!errorContact?.["phone"] ? errorContact?.["phone"] : ""
                }
              />
            </div>
            <div className="contactComponent__rowForm no-left-margin">
              <TextField
                required
                id="title"
                label={"Title"}
                value={contactValue?.title}
                onChange={handleChange}
              />
            </div>
            <div className="contactComponent__btnWrap">
              <Button variant="contained" type="submit" disabled={!submitButtonNotDisabled}>
                {updateForm ? 'Update' : 'Add'}
              </Button>
              {updateForm && (
                <Button variant="contained" onClick={cancelUpdateHandler}>
                  Cancel
                </Button>
              )}
            </div>
          </form>
        )}
        {!!contactsList?.length &&
        contactsList.map((item, index) => (
          <div className="contactComponent__rowContacts" key={index}>
            <div className="contactComponent__colInfo">
              <p className='contactComponent__colInfo--bigger-text'>{item?.firstName} {item?.lastName}</p>
              <p>{item?.title}</p>
              <p>{item?.email}</p>
            </div>
            <div className="contactComponent__colAction">
              <ul className={contactForm && 'disabled'}>
                <li>
                  <a href={`tel:${item.phone}`}>
                    <i className="icons8-call"/>
                  </a>
                </li>
                <li>
                  <i
                    className="icons8-edit-2"
                    onClick={() => editAccountHandler(item.id)}
                  />
                </li>
                <li>
                  <i
                    className="icons8-trash-can"
                    onClick={() => deleteAccountHandler(item.id)}
                  />
                </li>
              </ul>
              <span>{item?.phone}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
};

export default ContactFormComponent;
