/**
 * @file
 * Contains constants of API endpoints.
 */
export const MAIN_API_URL = process.env.REACT_APP_MAIN_API_URL || "https://sales.feeagle.com/api/";
export const MARKETING_API_URL = process.env.REACT_APP_MARKETING_API_URL || "https://support.feeagle.com/api/";
export const WORKCENTER_LINK = process.env.REACT_APP_WORKCENTER_LINK || '';
export const ANALYTICS_LINK = process.env.REACT_APP_ANALYTICS_LINK || '';
// export const ANALYTICS_LINK = 'http://localhost:3010/analytics';

export const LOGIN_ENDPOINT = "/login";
export const RESTORE_PASSWORD_CODE_ENDPOINT = "/generate/validation/code";
export const RESTORE_PASSWORD_ENDPOINT = "/reset/password";
export const REFRESH_TOKEN = "/refresh-token";

export const SIGNUP_ENDPOINT = "/signup";
export const LEADS_ENDPOINT = "/leads";
export const CONTACTS_ENDPOINT = "/contacts";
export const CAMPAIGN_ENDPOINT = "/campaigns";
export const CAMPAIGN_TRACKS_ENDPOINT = "/tracks";
export const ACCOUNTS_ENDPOINT = "/accounts";
export const SALES_ENDPOINT = "/sales";
export const PROFILE_ENDPOINT = "/profile";
export const RESET_PASSWORD_ENDPOINT = "/reset/password";
export const ROLES_ENDPOINT = "/roles";
export const CALL_LOGS_ENDPOINT = "/call/logs";
export const CALL_RECORDINGS_ENDPOINT = "/call/recordings";
export const INVITE_USER = "/invite/user";
export const USERS_SIP = "/sip/users";
export const AVAILABLE_PHONE = "/available/phone";
export const SIP_DOMAINS = "/sip/domains";
export const EMAIL_SIP = "/voice/email";
export const PASSWORD_SIP = "/sip/password";
export const RELEASE_PHONE = "/release/phone";
export const ASSIGN_PHONE = "/assign/phone";
export const PHONE_LIST = "/phone/list";
export const SALES_USERS_ROLES_ENDPOINT = `/roles/users`;
export const USERS_BY_ROLES_ENDPOINT = `/users/roles`;

export const ACCOUNTS_CONVERT_ENDPOINT = `${ACCOUNTS_ENDPOINT}/convert`;
export const LEADS_STATUS_ENDPOINT = `${LEADS_ENDPOINT}/status`;
export const LEADS_SEARCH_ENDPOINT = `${LEADS_ENDPOINT}/search`;
export const SALES_USERS_ENDPOINT = `${SALES_ENDPOINT}/users`;
export const STATUS_USERS_ENDPOINT = `${SALES_ENDPOINT}/users/status`;
export const PROFILE_ADDRESS_ENDPOINT = `${PROFILE_ENDPOINT}/address`;
export const UPDATE_AVATAR_ENDPOINT = `${PROFILE_ENDPOINT}/avatar`;
export const CONTACTS_LIST_ENDPOINT = `${CONTACTS_ENDPOINT}/lead`;
export const CAMPAIGN_ENDPOINT_CREATE_TYPES = `${CAMPAIGN_ENDPOINT}/types`;
export const CAMPAIGN_ENDPOINT_CREATE_STATUS = `${CAMPAIGN_ENDPOINT}/status`;
