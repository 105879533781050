import React, { useEffect, useState } from "react";
import './campaignList.scss';
import TableElement from '../../components/TableElement/TableElement';
import EmptyListElement from '../../components/EmptyListElement/EmptyListElement';
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { addNewCampaignWindow, campaignState } from '../../pages/campaign/campaign-slice';
import SingleView from "../singe-view-campaign/SingleViewCampaign";

const CampaignListScreen = () => {
  const tableCells = {
    name: { name: "Campaign Name" },
    type: { name: "Type" },
    status: { name: "Status" },
    startDate: { name: "Start Date" },
    endDate: { name: "End Date" },
  };

  const dispatch = useDispatch();

  const tableData = useSelector(campaignState);

  const [singleCampaignState, setSingleCampaignState] = useState(false);
  const [singleCampaign, setSingleCampaign] = useState("");

  const tableRowClickHandler = (id) => {
    setSingleCampaign(id);
    setSingleCampaignState(true);
  };

  return (
    <div className="campaign-list">
      {singleCampaignState && (
        <SingleView
          id={singleCampaign}
          type="campaign"
          closeSingleViewHandler={() => setSingleCampaignState(false)}
        />
      )}
      <div className="table-header">
        <div className="table-header__page-title">Create new campaigns and track existing campaign listed</div>
        <Button
            className="add-button add-campaign"
            onClick={() => dispatch(addNewCampaignWindow(true))}
            variant="contained"
            color="primary"
        >
            + New Campaign
        </Button>
        </div>

      {Object.keys(tableData).length ? (
          <TableElement
            tableData={tableData}
            tableCells={tableCells}
            tableRowClickHandler={tableRowClickHandler}
          />
        ) : (
          <EmptyListElement text="No Campaigns Found" />
        )
      }
    </div>
  )
};

export default CampaignListScreen;
