/**
 * @file
 * Contains Leads service.
 */
import { apiClient } from "./apiClients.service";
import {
  LEADS_ENDPOINT,
  LEADS_STATUS_ENDPOINT,
  LEADS_SEARCH_ENDPOINT,
} from "../constants/apiEnpoints.constant";

/**
 * Get Leads List.
 */
const getLeads = async (params) => {
  try {
    const response = await apiClient.get(LEADS_ENDPOINT, { params });
    return response.data;
  } catch (e) {
    console.error(e);
  }
};

/**
 * Search Leads.
 */
const searchLeads = async (params) => {
  try {
    const response = await apiClient.post(`${LEADS_SEARCH_ENDPOINT}?page=0&size=10`, { ...params });
    return response.data;
  } catch (e) {
    console.error(e);
  }
};

/**
 * Get Lead details.
 * @param {string} id.
 * Lead ID
 */
const getLead = async (id) => {
  try {
    const response = await apiClient.get(`${LEADS_ENDPOINT}/${id}`);
    return response.data;
  } catch (e) {
    console.error(e);
  }
};

/**
 * Update Lead details.
 * @param {string} id.
 * Lead ID
 * @param {object} field.
 * Updated field.
 */
const updateLead = async (id, field) => {
  try {
    await apiClient.put(`${LEADS_ENDPOINT}/${id}`, field);
  } catch (e) {
    console.error(e);
  }
};

/**
 * Set Note.
 * @param {string} id.
 * Lead ID
 * @param {object} params.
 * Updated field.
 */
const setNote = async (id, params) => {
  try {
    const response = await apiClient.post(
      `${LEADS_ENDPOINT}/${id}/notes`,
      params
    );
    return response.data;
  } catch (e) {
    console.error(e);
  }
};

/**
 * Get Leads status.
 */
const getLeadsStatusApi = async () => {
  try {
    const response = await apiClient.get(LEADS_STATUS_ENDPOINT);
    return response.data;
  } catch (e) {
    console.error(e);
  }
};

export default {
  getLeads,
  getLead,
  updateLead,
  setNote,
  getLeadsStatusApi,
  searchLeads,
};
