import React, { useEffect, useRef, useState } from "react";
import "./profileDialog.css";
import { TextField, Button, Avatar } from "@material-ui/core";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormHelperText from "@material-ui/core/FormHelperText";
import FeUtils from "@fe-sales/common-utils/fe-utils";
import Bridge from "@fe-sales/analytics/src/bridge";
import { SALES_LINK } from "@fe-sales/analytics/src/constants/apiEnpoints.constant";

const CommonProfileDialog = (props) => {
  const { closeProfile, profileData, avatarData, ProfileService, updateProfile, updateAvatar, commonSipData } = props;

  const [values, setValues] = useState(profileData);
  const [resetPassword, setResetPassword] = useState(false);
  const [passwordData, setPasswordData] = useState({});
  const [errors, setErrors] = useState({});
  const [cleanForm, setCleanForm] = useState(true);
  const [message, setMessage] = useState({ msg: '', type: 'error' });
  const [sipData, setSipData] = useState(commonSipData);
  const [responseSipData, setResponseSipData] = useState(commonSipData);

  const [errorState, setErrorState] = useState([]);
  const [errorSipState, setErrorSipState] = useState({});
  const [sipUpdatedState, setSipUpdatedState] = useState(false);
  const inputOpenFileRef = useRef();

  useEffect(() => {
    checkResetFields();
  }, [passwordData]);

  useEffect(() => {
    if(sipUpdatedState) {
      setTimeout(() => setSipUpdatedState(false), 2000)
    }
  }, [sipUpdatedState]);

  useEffect(() => {
    const mandatoryFields = ["firstName", "lastName", "email", "mobile"];
    const errorFields = mandatoryFields.filter((item) => (item in values) && !values?.[item])
    setErrorState(prevState => [...prevState, ...errorFields])
  }, [values])

  const cleanResetForm = () => {
    setCleanForm(true);
    setMessage({ msg: '', type: 'error' });
    setPasswordData({});
    setErrors({});
  };

  const resetPasswordHandler = () => {
    setResetPassword(!resetPassword);
    cleanResetForm();
  };

  const cancelHandler = () => {
    setResetPassword(!resetPassword);
    cleanResetForm();
  };

  const handleChange = (event) => {
    setErrorState([]);
    let updatedValue = "";
    const { id, value } = event.target;
    if (id === "mobile" && value[0] !== "+") {
      updatedValue = `+${value}`;
    } else {
      updatedValue = value;
    }
    if (id === "email") {
      !FeUtils.validateEmail(value) && setErrorState([...errorState, id]);
    }

    if (id === "mobile") {
      !FeUtils.phoneNumberValidation(value) &&
      setErrorState([...errorState, id]);
    }

    setValues({ ...values, [id]: updatedValue });
  };

  const handleAddressChange = (event) => {
    const { id, value } = event.target;
    setErrorState([]);
    !value && setErrorState([...errorState, id]);
    setValues({ ...values, address: { ...values.address, [id]: value } });
  };

  const handlePasswordChange = (event) => {
    setErrors({});
    setPasswordData({ ...passwordData, [event.target.id]: event.target.value });
  };

  const handleClickShowPassword = (key) => {
    setPasswordData({ ...passwordData, [key]: !passwordData[key] });
  };

  const handleClickClose = () => {
    closeProfile();
  };

  const saveHandler = async (e) => {
    e.preventDefault();
    if (JSON.stringify(profileData) !== JSON.stringify(values)) {
      let errorFields = [];
      Object.entries(values).forEach(([key, value]) => {
        if (key !== "owner") {
          !value && errorFields.push(key);
        }

        if (key === "email") {
          !FeUtils.validateEmail(value) && errorFields.push(key);
        }

        if (key === "mobile") {
          !FeUtils.phoneNumberValidation(value) && errorFields.push(key);
        }

        if (key === "address") {
          const addressFields = ["zip", "country", "subDivision", "city", "address1"];
          addressFields.forEach((fieldKey) => {
            (!(fieldKey in values[key]) || !values[key]?.[fieldKey]) && errorFields.push(fieldKey);
          });
        }
      });

      if (errorFields.length) {
        setErrorState(errorFields);
        return;
      }

      try {
        await ProfileService.updateProfile(values);
        await ProfileService.updateProfileAddress(values);
        updateProfile(values);
        closeProfile();
      } catch (e) {
        console.error(e);
      }
    }
  };

  const passwordMatch = () => passwordData.password === passwordData.repassword;

  const timeout = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const savePasswordHandler = async (e) => {
    e.preventDefault();

    try {
      await ProfileService.updatePassword(passwordData.password);
      setMessage({ msg:"Password successfully changed", type: 'success' });

      await timeout(2000);
      resetPasswordHandler();
      Bridge.removeJwtToken();
      window.location.href = SALES_LINK;
    } catch (e) {
      cleanResetForm();
      setMessage({ msg: "Error", type: 'error' });
    }
  };

  const checkResetFields = () => {
    if (
      passwordData?.password?.length > 0 &&
      passwordData?.password?.length < 7
    ) {
      setErrors({ password: true });
      return false;
    }

    if (
      passwordData.password &&
      !FeUtils.validatePassword(passwordData.password)
    ) {
      setErrors({ password: true });
      return false;
    }

    if (passwordData?.repassword?.length > 0 && !passwordMatch()) {
      setErrors({ passwordReType: true });
      return false;
    }

    if (
      passwordData?.password?.length > 0 &&
      passwordData?.repassword?.length > 0
    ) {
      setCleanForm(false);
    }
  };

  const onImageUpload = async (e) => {
    const image = e.target.files[0];
    const formData = new FormData();
    formData.append("file", image);

    const newImages = await FeUtils.fileToDataUri(image);

    updateAvatar(newImages.base64);
    try {
      await ProfileService.updateAvatar(formData);
      setMessage({ msg:"Avatar successfully changed", type: 'success' });
    } catch {
      setMessage({ msg:"Error", type: 'success' });
    }
  };

  const handleSipChange = (e) => {
    const { id, value } = e.target;

    if ((id === 'email') && !FeUtils.validateEmail(value))  {
      setErrorSipState({...errorSipState, [id]: 'Not a valid Email'});
    } else if ((id === 'password') && !FeUtils.validatePassword(value, 12) && value)  {
      setErrorSipState({...errorSipState, [id]: 'Min 12 characters, capital and number'});
    } else {
      const copyOfErrorSipState = {...errorSipState};
      delete copyOfErrorSipState[id];
      setErrorSipState({...copyOfErrorSipState});
    }

    setSipData({...sipData, [id]: value})
  }

  const updateSipDataHandler = async (e) => {
    e.preventDefault();
    if (Object.keys(errorSipState).length) {
      return;
    }

    if (responseSipData.email !== sipData.email) {
      try {
        await SipService.updateSipEmail({phone: sipData.phone, email: sipData.email});
        setResponseSipData({...responseSipData, email: sipData.email});
        setSipUpdatedState(true);
      } catch (e) {
        console.error(e);
      }
    }

    if ('password' in sipData) {
      try {
        await SipService.updateSipPassword({phone: sipData.phone, password: sipData.password});
        setSipUpdatedState(true);
        const copyOfSipData = {...sipData};
        delete copyOfSipData['password'];
        setSipData({...copyOfSipData});
      } catch (e) {
        console.error(e);
      }
    }
  };

  return (
    <div className="profileDialog">
      <header className="profileDialog__header">
        <div className="profileDialog__title">
          <h4>Profile</h4>
        </div>

        <div>
          <IconButton
            aria-label="close dialog"
            className="profileDialog__close"
            onClick={handleClickClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </header>

      <div className="profileDialog__container">
        {!resetPassword && (
          <>
            <div className="profileDialog__avatar">
              <div className="upload__image-wrapper">
                <div className="upload__edit" onClick={() => inputOpenFileRef.current.click()}>
                  <i className="icons8-upload" />
                </div>
                <input
                  ref={inputOpenFileRef}
                  type="file"
                  onChange={onImageUpload}
                  hidden
                />
                {avatarData ? (
                  <div className="image-item">
                    <Avatar src={avatarData} />
                  </div>
                ) : (
                  <Avatar src="assets/img/profile-default.png" />
                )}
              </div>
            </div>
            <form className="profileDialog__form" onSubmit={saveHandler}>
              <TextField
                required
                id="firstName"
                label={"First Name"}
                value={values.firstName}
                error={errorState.includes("firstName")}
                onChange={handleChange}
              />
              <TextField
                required
                id="lastName"
                label={"Last Name"}
                value={values.lastName}
                error={errorState.includes("lastName")}
                onChange={handleChange}
              />
              <TextField
                id="email"
                label={"Email"}
                value={values.email}
                onChange={handleChange}
                error={errorState.includes("email")}
                disabled={!!values?.email}
              />
              <TextField
                id="mobile"
                label={"Mobile"}
                value={values.mobile}
                onChange={handleChange}
                error={errorState.includes("mobile")}
                placeholder="+..."
              />
              <TextField
                id="address1"
                label={"Address 1"}
                value={values.address?.address1}
                onChange={handleAddressChange}
                error={errorState.includes("address1")}
              />
              <TextField
                id="address2"
                label={"Address 2"}
                value={values.address?.address2}
                onChange={handleAddressChange}
                error={errorState.includes("address2")}
              />
              <TextField
                id="city"
                label={"City"}
                value={values.address?.city}
                onChange={handleAddressChange}
                error={errorState.includes("city")}
              />
              <TextField
                id="subDivision"
                label={"State"}
                value={values.address?.subDivision}
                onChange={handleAddressChange}
                error={errorState.includes("subDivision")}
              />
              <TextField
                id="zip"
                label={"Zip"}
                value={values.address?.zip}
                onChange={handleAddressChange}
                error={errorState.includes("zip")}
                type="number"
              />
              <TextField
                id="country"
                label={"Country"}
                value={values.address?.country}
                onChange={handleAddressChange}
                error={errorState.includes("country")}
              />
              <div className="profileDialog__btn_container">
                <Button
                  className="profileDialog__btn"
                  variant="contained"
                  color="primary"
                  disabled={
                    JSON.stringify(profileData) === JSON.stringify(values) ||
                    errorState.length
                  }
                  type="submit"
                >
                  Save
                </Button>
              </div>
            </form>
          </>
        )}
        <button
          className="profileDialog__resetPassword"
          onClick={resetPasswordHandler}
        >
          Reset Password
        </button>

        {resetPassword && (
          <>
            <form
              className="profileDialog__form"
              onSubmit={savePasswordHandler}
            >
              <FormControl error={!!errors?.password}>
                <InputLabel htmlFor="standard-adornment-password">
                  Password
                </InputLabel>
                <Input
                  id="password"
                  type={passwordData.showPassword ? "text" : "password"}
                  value={passwordData.password}
                  aria-describedby="helper-text-password"
                  onChange={handlePasswordChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => handleClickShowPassword("showPassword")}
                      >
                        {passwordData.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {errors?.password && (
                  <FormHelperText id="helper-text-password">
                    Min 8 characters, capital and number
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl error={!!errors?.passwordReType}>
                <InputLabel htmlFor="standard-adornment-password">
                  Repeat Password
                </InputLabel>
                <Input
                  id="repassword"
                  type={passwordData.showRePassword ? "text" : "password"}
                  value={passwordData.repassword}
                  aria-describedby="helper-text-repassword"
                  onChange={handlePasswordChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle repeat password visibility"
                        onClick={() =>
                          handleClickShowPassword("showRePassword")
                        }
                      >
                        {passwordData.showRePassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {errors?.passwordReType && (
                  <FormHelperText id="helper-text-repassword">
                    Passwords don't match
                  </FormHelperText>
                )}
              </FormControl>
              <div>
                <p className="profileDialog__form_text">
                  Password must be at least 8 characters long and contain a
                  digit, an uppercase letter and a lowercase letter
                </p>
                <p className={`profileDialog__msg--${message?.type}`}>{message?.msg}</p>
              </div>

              <div className="profileDialog__btn_container">
                <Button
                  disabled={
                    cleanForm || errors.password || errors.passwordReType
                  }
                  // disabled={cleanForm}
                  className="profileDialog__btn"
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Reset
                </Button>
                <Button
                  className="profileDialog__btn2"
                  variant="outlined"
                  color="primary"
                  onClick={cancelHandler}
                >
                  Cancel
                </Button>
              </div>
            </form>
          </>
        )}
        {sipData?.phone && (
          <form
            className="profileDialog__form sip-details"
            onSubmit={updateSipDataHandler}
          >
            <div className="sip-details__title">Assigned Phone</div>
            <TextField
              id="phone"
              label={"Phone"}
              value={sipData?.phone}
              disabled={true}
            />
            <TextField
              id="domain"
              label={"SIP Domain"}
              value={sipData?.domain}
              disabled={true}
            />
            <TextField
              id="email"
              label={"Email For Voice Mail"}
              value={sipData?.email}
              onChange={handleSipChange}
              error={!!errorSipState?.email}
            />
            <TextField
              id="password"
              label={"SIP Password"}
              value={sipData?.password ? sipData?.password : ''}
              onChange={handleSipChange}
              error={!!errorSipState?.password}
              helperText={!!errorSipState?.password ? errorSipState?.password : ""}
            />
            <Button
              className={`profileDialog__btn ${sipUpdatedState && 'updated'}`}
              variant="contained"
              color="primary"
              type="submit"
              disabled={JSON.stringify(responseSipData) === JSON.stringify(sipData)}
            >
              {sipUpdatedState ? 'Updated' : 'Update Phone'}
            </Button>
          </form>
        )}
      </div>
    </div>
  );
};

export default CommonProfileDialog;
