/**
 * @file
 * Contains Accounts service.
 */

import { apiClient } from "./apiClients.service";
import {
  ACCOUNTS_CONVERT_ENDPOINT,
  ACCOUNTS_ENDPOINT,
} from "../constants/apiEnpoints.constant";

/**
 * Get Accounts List.
 */
const getAccountsList = async (params = {}) => {
  try {
    const response = await apiClient.get(ACCOUNTS_ENDPOINT, {params});
    return response.data;
  } catch (e) {
    console.error(e);
  }
};

/**
 * Convert Lead to Account.
 * @param {string} id.
 * Lead ID
 */
const convertLeadToAccount = async (id) => {
  try {
    await apiClient.put(`${ACCOUNTS_CONVERT_ENDPOINT}/${id}`);
  } catch (e) {
    console.error(e);
  }
};

/**
 * Get Lead details.
 * @param {string} id.
 * Lead ID
 */
const getAccount = async (id) => {
  try {
    const response = await apiClient.get(`${ACCOUNTS_ENDPOINT}/${id}`);
    return response.data;
  } catch (e) {
    console.error(e);
  }
};

/**
 * Update Lead details.
 * @param {string} id.
 * Lead ID
 * @param {object} field.
 * Updated field.
 */
const updateAccount = async (id, field) => {
  try {
    await apiClient.put(`${ACCOUNTS_ENDPOINT}/${id}`, field);
  } catch (e) {
    console.error(e);
  }
};

export default {
  convertLeadToAccount,
  getAccountsList,
  getAccount,
  updateAccount,
};
