import React from "react";
import "./signUp.scss";
import { SignUpPage } from "@fe-sales/common-ui";
import logo from "./../../assets/img/logo.svg";
import urls from "../../routes/urls";

const SignUpComponent = (props) => {
  const { history } = props;

  const signUpHandler = (userCredentials) => {
    console.log("Sign Up Cred is : ", userCredentials);
    history.push({
      pathname: urls.dashboard,
    });
  };

  const loginLinkHandler = () => {
    console.log("login click");
  };

  return (
    <div className="signup-container">
      <SignUpPage
        createAccountHandler={signUpHandler}
        title={"Welcome to Fieldengineer CRM"}
        companyLogo={logo}
        styles={{
          logoStyle: { width: "100px", height: "100px" },
          titleStyle: { color: "#FFF", fontSize: "1.6rem", padding: "10px" },
          formStyle: { backgroundColor: "rgba(255, 255, 255, 0.2)" },
        }}
        loginLinkHandler={loginLinkHandler}
      />
    </div>
  );
};

export default SignUpComponent;
