/**
 * @file
 * Contains API clients service.
 */

import {
  MAIN_API_URL,
  MARKETING_API_URL,
} from "../constants/apiEnpoints.constant";
import Bridge from "../bridge";
import urls from "../routes/urls";
import createClient from "./apiClientFactory.service";
import authService from '../services/auth.service';

const pureClient = createClient({
  baseUrl: MAIN_API_URL,
});

const apiClient = createClient({
  baseUrl: MAIN_API_URL,
});

const externalApiClient = createClient({
  baseUrl: MAIN_API_URL,
});

const marketingApiClient = createClient({
  baseUrl: MARKETING_API_URL,
});

const pureClientInterceptorsReq = (config) => ({
  ...config,
});

const pureClientInterceptorsReqError = (error) => Promise.reject(error);

const pureClientInterceptorsRes = (response) => response;

const pureClientInterceptorsResError = (error) => {
  const { status } = error.response;

  setTimeout(() => {
    Bridge.removeJwtToken();
    if (status === 401) {
      window.location.href = urls.public.login;
    } else {
      window.location.href = urls.public.error;
    }
  }, 3000)

  return Promise.reject(error.response);
};

const apiClientInterceptorsReq = async (config) => {
  return({
  ...config,
  headers: {
    "content-type": "application/json",
    authorization: Bridge.getJwtToken(),
    ...config.headers,
  },
})};

const marketingClientInterceptorsReq = (config) => ({
  ...config,
  headers: {
    "content-type": "application/json",
    authorization: Bridge.getJwtToken(),
    "x-api-key": "29080d5c-33d2-11ea-850d-2e728ce88125",
    ...config.headers,
  },
});

const apiClientInterceptorsReqError = (error) => Promise.reject(error);

const apiClientInterceptorsRes = (response) => response;

const apiClientInterceptorsResError = async (error) => {
  if (error.response) {
    const originalConfig = error.config;
    Bridge.removeJwtToken();

    if (error.response.status === 401 && !originalConfig._retry) {
      originalConfig._retry = true;
      await authService.restoreJwtToken();
      return apiClient({
        ...originalConfig,
        headers: {
          "content-type": "application/json",
          authorization: Bridge.getJwtToken(),
        }});
    } else {
      window.location.href = urls.public.error;
    }
  }

  return Promise.reject(error);
}

pureClient.interceptors.request.use(
  pureClientInterceptorsReq,
  pureClientInterceptorsReqError
);
pureClient.interceptors.response.use(
  pureClientInterceptorsRes,
  pureClientInterceptorsResError
);
marketingApiClient.interceptors.request.use(
  marketingClientInterceptorsReq,
  apiClientInterceptorsReqError
);
apiClient.interceptors.request.use(
  apiClientInterceptorsReq,
  apiClientInterceptorsReqError
);
apiClient.interceptors.response.use(
  apiClientInterceptorsRes,
  apiClientInterceptorsResError
);

export { pureClient, apiClient, externalApiClient, marketingApiClient };
