import { createSlice } from "@reduxjs/toolkit";

export const appSlice = createSlice({
  name: "appSlice",
  initialState: {
    url: '',
    redirect: false,
    adminRoles: ['analytics-admin', 'crm-admin', 'workcenter-super-user', 'workcenter-admin'],
    wcRoles: ['Workcenter User', 'Workcenter Administrator', 'Workcenter SuperUser'],
    salesRoles: ['CRM Administrator', 'CRM User'],
    analyticsRoles: ['Analytics Administrator', 'Analytics User'],
  },
  reducers: {
    updateUrl: (state, data) => {
      state.url = data.payload;
    },
    setRedirectState: (state, data) => {
      state.redirect = data.payload;
    },
  },
});

export const audioPlayerSliceUrl = (state) => state.appSlice.url;
export const redirectState = (state) => state.appSlice.redirect;
export const adminRolesState = (state) => state.appSlice.adminRoles;
export const wcRolesState = (state) => state.appSlice.wcRoles;
export const salesRolesState = (state) => state.appSlice.salesRoles;
export const analyticsRolesState = (state) => state.appSlice.analyticsRoles;

export const { updateUrl, setRedirectState } = appSlice.actions;

export default appSlice.reducer;
