/**
 * @file
 * Contains Logout component.
 */

import React, { useEffect } from "react";
import urls from "../../routes/urls";
import Bridge from "../../bridge";
import "./logout.scss";
import { BackdropElement } from '@fe-sales/common-ui';

function Logout() {
  /**
   * Logout.
   */
  useEffect(() => {
    Bridge.removeJwtToken();
    Bridge.removeJwtRefresh();
    window.location.href = urls.public.login;
  }, []);

  return (
    <div className="logout-container">
      <BackdropElement />
    </div>
  );
}

export default Logout;
