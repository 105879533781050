/**
 * @file
 * Contains bridge export.
 * Needed to connect with native device services.
 */

import {
  saveJwtToken,
  getJwtToken,
  removeJwtToken,
  saveJwtTokenExpires,
  saveJwtRefreshToken,
  getJwtRefreshToken,
  getJwtRefreshTokenExpires,
  saveJwtRefreshTokenExpires,
  getJwtTokenExpires,
  removeJwtRefresh,
} from "./jwtToken.bridge";

export default {
  saveJwtToken,
  getJwtToken,
  removeJwtToken,
  saveJwtTokenExpires,
  saveJwtRefreshToken,
  getJwtRefreshToken,
  getJwtRefreshTokenExpires,
  saveJwtRefreshTokenExpires,
  getJwtTokenExpires,
  removeJwtRefresh,
};
