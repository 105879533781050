import React, { useEffect, useState } from "react";
import PageLayout from "../../components/page-layout/page-layout";
import { Tabs, Tab } from "@material-ui/core";
import "./leads.scss";
import { useDispatch, useSelector } from "react-redux";
import SingleView from "../../components/singe-view/SingleView";
import { leadsState, addLeads } from "./leads-slice";
import LeadsService from "../../services/leads.service";
import TableElement from "../../components/TableElement/TableElement";
import BackdropElement from "../../components/BackdropElement/BackdropElement";
import EmptyListElement from "../../components/EmptyListElement/EmptyListElement";
import CallLogsService from "../../services/callLogs.service";
import {addCallLogs} from "../callLogs/call-logs-slice";
import Pagination from '../../components/Pagination/Pagination';
import GlobalAppConstant from '../../constants/globalAppConstant';

const LeadsComponent = (props) => {
  const { location } = props;

  const tableCells = {
    name: { name: "Lead" },
    companyName: { name: "Company" },
    email: { name: "Email" },
    recordCreated: { name: "Created On", extra: "dates" },
    source: { name: "Source" },
  };

  const tableCellsMyLeads = {
    name: { name: "Lead" },
    companyName: { name: "Company" },
    email: { name: "Email" },
    recordCreated: { name: "Created On", extra: "dates" },
    source: { name: "Source" },
    status: { name: "Status" },
  };

  const tabName = {
    0: 'unassigned',
    1: 'myleads',
    2: 'today',
    3: 'inprogress',
  };

  const dispatch = useDispatch();

  const leadsStateData = useSelector(leadsState);

  const tableData = leadsStateData.leads;

  const [currentLeadsTab, setCurrentLeadsTab] = useState(location?.tab ? location?.tab : "unassigned");

  const [singleLead, setSingleLead] = useState("");

  const [value, setValue] = useState(0);

  const [dataLoaded, setDataLoaded] = useState(false);

  const [paginationState, setPaginationState] = useState(0);
  const [updateLeads, setUpdateLeads] = useState(false);

  useEffect(() => {
    if (location?.tab) {
      setValue(Number(Object.keys(tabName).find(key => tabName[key] === location?.tab)));
    }
  }, [])

  const fetchData = async () => {
    setDataLoaded(false);
    try {
      const response = await LeadsService.getLeads({
        category: currentLeadsTab,
        filter: {
          limit: GlobalAppConstant.defaultTableRows,
          skip: paginationState
        }
      });
      dispatch(addLeads(response));
    } catch (e) {
      console.error(e);
    } finally {
      setDataLoaded(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, [dispatch, currentLeadsTab, updateLeads, paginationState]);

  /**
   * Table Row Click Handler.
   * @param id
   */
  const tableRowClickHandler = (id) => {
    setSingleLead(id);
  };

  const setCurrentLeadTabHandler = (tabSlag) => {
    setCurrentLeadsTab(tabSlag);
  };

  const handleChange = (event, newValue) => {
    setSingleLead("");
    setCurrentLeadsTab(tabName[newValue]);
    setValue(newValue);
    setPaginationState(0)
  };

  const paginationClickHandler = async (key) => {
    if (key === 'next') {
      setPaginationState(paginationState + GlobalAppConstant.defaultTableRows)
    }

    if (key === 'prev') {
      setPaginationState(paginationState - GlobalAppConstant.defaultTableRows)
    }
  };

  return (
    <PageLayout>
      {singleLead && (
        <SingleView
          id={singleLead}
          type='lead'
          closeSingleViewHandler={tableRowClickHandler}
          updateParentComponent={() => setUpdateLeads(!updateLeads)}
        />
      )}
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="on"
        className="tabs"
        onChange={handleChange}
        aria-label="scrollable auto tabs"
      >
        <Tab label="Unassigned" />
        <Tab label="My Leads" />
        <Tab label="Today's" />
        <Tab label="In Progress" />
      </Tabs>

      {!!dataLoaded ? (
        <>
          {Object.keys(tableData).length && (
            <TableElement
              tableData={tableData}
              activeRow={singleLead}
              tableRowClickHandler={tableRowClickHandler}
              setCurrentLeadTab={setCurrentLeadTabHandler}
              tableCells={
                currentLeadsTab === "myleads" ? tableCellsMyLeads : tableCells
              }
            />
          )}
          {!Object.keys(tableData).length && (
            <EmptyListElement text="No Leads Found" />
          )}
          <Pagination
            paginationState={paginationState}
            tableData={tableData}
            paginationClickHandler={paginationClickHandler}
          />
        </>
      ) : <BackdropElement />}
    </PageLayout>
  );
};

export default LeadsComponent;
