/**
 * @file
 * Contains API client factory service.
 */

import axios from "axios";

export default (config) =>
  axios.create({
    baseURL: config.baseUrl,
    headers: config.headers,
  });
